<template>
    <div class="repair-main-banner">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="repair-banner-content">
                        <h1>Your Local Computer Repair Experts!</h1>
                        <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
                        <a href="#" class="btn btn-primary">Get Started</a>
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="repair-banner-image">
                        <img src="../../../assets/img/repair-banner-image/repair-banner-img.png" alt="image">
                        <img src="../../../assets/img/repair-banner-image/1.png" alt="image">
                        <img src="../../../assets/img/repair-banner-image/2.png" alt="image">
                        <img src="../../../assets/img/repair-banner-image/circle.png" class="rotateme" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MainBanner'
}
</script>