<template>
  <div class="container my-5">
    <div class="section-title text-start mb-4">
      <h2 class="mb-4">{{ title }}</h2>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "DetailsOffreSection",
  props: {
    title: {
      type: String,
      default: "",
    },
  },
};
</script>
