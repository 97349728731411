<template>
  <div class="py-5">
    <nos-expertises-header />
    <div class="container">
      <!-- Bloc 1 -->
      <div class="row boxes-slides">
        <div class="col-md-6 pb-4" v-for="slide in slides" :key="slide.id">
          <card-with-line-generic
            type="dashed"
            class="h-100"
            :class="slide.class"
          >
            <template v-slot:title>
              <div class="icon">
                <vue-feather :type="slide.icon"></vue-feather>
              </div>
              <h3 class="ms-3 my-auto">{{ slide.title }}</h3>
            </template>
            <template v-slot:body>
              <div v-html="slide.desc.title"></div>
              <ul class="tw-list-disc ms-3">
                <li v-for="item in slide.desc.items" :key="item">
                  {{ item }}
                </li>
              </ul>
            </template>
          </card-with-line-generic>
        </div>
      </div>
    </div>
    <!-- Bloc 2 -->
    <div class="tw-bg-[#081c37] tw-py-10" v-if="false">
      <div class="container">
        <div class="text-start">
          <div class="row bg-white">
            <div class="col-md-6" v-for="i in 2" :key="i">
              <card-with-line-generic class="tw-rounded-none" bodySize="s">
                <template v-slot:body>
                  <div class="d-flex gap-3">
                    <div>
                      <img
                        src="https://www.cat-amania.com/wp-content/uploads/2023/09/ISO-9001-500x541.jpg"
                        class="img-fluid"
                      />
                    </div>
                    <div>
                      <h1 class="tw-text-xl p-0">ISO 9001</h1>
                      <div
                        class="tw-my-3 tw-h-0.5 tw-bg-orange-500 tw-border-opacity-50 tw-w-32"
                      ></div>
                      <h4 class="pt-1 pb-3">
                        {{ APP_NAME }} est certifié ISO 9001 depuis 2008
                      </h4>
                      <p class="tw-font-light">
                        {{ APP_NAME }} a obtenu et renouvelé la certification
                        ISO 9001 depuis 2008 ; gage de rigueur dans
                        l’organisation de l’entreprise et ce, pour l’ensemble de
                        ses processus de fonctionnement. Maintenir la
                        certification qualité reste une préoccupation constante
                        pour {{ APP_NAME }}, afin d’optimiser nos pratiques et
                        d’améliorer le quotidien de tous. L’objectif central
                        étant l’efficacité mise en place afin de répondre au
                        mieux aux exigences de nos clients et de nos
                        collaborateurs.
                      </p>
                    </div>
                  </div>
                </template>
              </card-with-line-generic>
            </div>
          </div>
        </div>
      </div>
    </div>

    <nos-expertises-solutions />
  </div>
</template>

<script>
import {
  APP_ADDRESS,
  APP_EMAIL,
  APP_NAME,
  APP_PHONE,
} from "../../statics/app_static";
import CardWithLineGeneric from "../generics/Cards/CardWithLine/CardWithLineGeneric.vue";
import NosExpertisesSolutions from "./accueil-components/NosExpertisesSolutions/NosExpertisesSolutions.vue";

import NosExpertisesHeader from "./NosExpertises/NosExpertisesHeader.vue";

export default {
  name: "NosExpertises",
  components: {
    NosExpertisesHeader,
    CardWithLineGeneric,
    NosExpertisesSolutions,
  },
  data() {
    return {
      APP_NAME,
      APP_ADDRESS,
      APP_EMAIL,
      APP_PHONE,
      slides: [
        {
          id: 1,
          class: "",
          icon: "hard-drive",
          title: "Infrastructures & Opérations IT",
          desc: {
            title: `Les infrastructures IT sont le socle de toute entreprise. 
              Notre expertise en gestion des opérations IT vous garantit une optimisation continue des 
              systèmes pour maintenir un niveau de service élevé et aligné avec vos objectifs métiers.`,
            items: [
              "Gestion des infrastructures (serveurs, réseaux, stockage)",
              "Virtualisation et solutions de conteneurisation (VMware, Docker, Kubernetes)",
              "Supervision et monitoring des systèmes et applications",
              "Maintenance préventive et corrective des environnements IT",
              "Optimisation des environnements de production (SLA, disponibilité, performances)",
            ],
          },
        },
        {
          id: 2,
          class: "bg-f78acb",
          icon: "code",
          title: "Production, DevOps & Automatisation",
          desc: {
            title: `L’intégration des pratiques DevOps et l’automatisation des processus de déploiement permettent d’accélérer 
              les cycles de livraison tout en améliorant la qualité des applications. 
              Nous vous accompagnons dans l’industrialisation de votre production IT en adoptant une approche collaborative entre développement et exploitation.
              <br />
              Notre objectif : accroître la flexibilité et l’agilité des équipes tout en garantissant la stabilité des environnements de production.`,
            items: [
              "Implémentation et gestion de pipelines CI/CD (Jenkins, GitLab CI, GitHub Actions)",
              "Automatisation des infrastructures (Infrastructure as Code - IaC) ( Terraform, Ansible)",
              "Gestion des environnements de production et amélioration des processus ITIL",
              "Optimisation et supervision du cycle de vie des applications (de la conception à la production)",
              "Monitoring et gestion proactive des performances et des incidents",
            ],
          },
        },
        {
          id: 3,
          class: "bg-c679e3",
          icon: "cloud",
          title: "Cloud",
          desc: {
            title: `En tant que partenaire technologique de confiance nous accompagnons nos clients dans leur transition vers le cloud, 
              qu’il s’agisse de migration, d’intégration ou d’optimisation.`,
            items: [
              "Stratégie cloud (public, privé, hybride)",
              "Migration cloud & modernisation des infrastructures",
              "Sécurisation des environnements cloud",
              "Automatisation et gestion des infrastructures (IaC)",
              "Optimisation des coûts et performances",
            ],
          },
        },
        {
          id: 4,
          class: "bg-eb6b3d",
          icon: "database",
          title: "Data",
          desc: {
            title: `La gestion et l’exploitation de la donnée sont au cœur des enjeux de l’entreprise moderne.
            <br />
            Nos experts en data vous aident à valoriser votre capital data pour en faire un véritable outil de prise de décision et d’innovation.`,
            items: [
              "Data management (Big Data, Data Lake, Data Warehouse)",
              "Business Intelligence & reporting",
              "Modélisation et visualisation des données",
            ],
          },
        },
      ],
    };
  },
};
</script>
