<template>
    <div class="services-area ptb-80 bg-f7fafd">
        <div class="container">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-12 services-content">
                    <div class="section-title">
                        <h2>Cloud Hosting Services</h2>
                        <div class="bar"></div>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    </div>

                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="box">
                                <vue-feather type="database"></vue-feather> Cloud databases
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="box">
                                <vue-feather type="globe"></vue-feather> Website hosting
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="box">
                                <vue-feather type="file"></vue-feather> File storage
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="box">
                                <vue-feather type="trending-up"></vue-feather> Forex trading
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="box">
                                <vue-feather type="folder"></vue-feather> File backups
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="box">
                                <vue-feather type="monitor"></vue-feather> Remote desktop
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="box">
                                <vue-feather type="mail"></vue-feather> Email servers
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="box">
                                <vue-feather type="cloud"></vue-feather> Hybrid cloud
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12 services-right-image single-right-image">
                    <img src="../../../assets/img/services-right-image/cercle-shape.png" class="bg-image rotateme" alt="shape">
                    
                    <img src="../../../assets/img/services-right-image/main-pic.png" alt="main-pic">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'OurServices'
}
</script>