<template>
    <div class="bigdata-services-area ptb-80 bg-eef6fd">
            <div class="container">
                <div class="section-title">
                    <h2>Services We Can Help You With</h2>
					<div class="bar"></div>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-md-6 col-sm-12">
                        <div class="single-services-box-item">
                            <div class="icon">
                                <img src="../../../assets/img/bigdata-analytics/icon1.png" alt="image">
                            </div>
                            <h3><router-link to="/service-details">Big Data Analysis</router-link></h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod ut labore et dolore magna aliqua.</p>
                            <router-link to="/service-details" class="learn-more-btn"><vue-feather type="arrow-right"></vue-feather>Learn More</router-link>
                            <div class="shape"><img src="../../../assets/img/bigdata-analytics/rectangle.png" alt="image"></div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-12">
                        <div class="single-services-box-item">
                            <div class="icon">
                                <img src="../../../assets/img/bigdata-analytics/icon2.png" alt="image">
                            </div>
                            <h3><router-link to="/service-details">Robust Analytics</router-link></h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod ut labore et dolore magna aliqua.</p>
                            <router-link to="/service-details" class="learn-more-btn"><vue-feather type="arrow-right"></vue-feather>Learn More</router-link>
                            <div class="shape"><img src="../../../assets/img/bigdata-analytics/rectangle.png" alt="image"></div>
                        </div>
                    </div>
                    
                    <div class="col-lg-4 col-md-6 col-sm-12">
                        <div class="single-services-box-item">
                            <div class="icon">
                                <img src="../../../assets/img/bigdata-analytics/icon3.png" alt="image">
                            </div>
                            <h3><router-link to="/service-details">Marketing Analytics</router-link></h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod ut labore et dolore magna aliqua.</p>
                            <router-link to="/service-details" class="learn-more-btn"><vue-feather type="arrow-right"></vue-feather>Learn More</router-link>
                            <div class="shape"><img src="../../../assets/img/bigdata-analytics/rectangle.png" alt="image"></div>
                        </div>
                    </div>
                    
                    <div class="col-lg-4 col-md-6 col-sm-12">
                        <div class="single-services-box-item">
                            <div class="icon">
                                <img src="../../../assets/img/bigdata-analytics/icon4.png" alt="image">
                            </div>
                            <h3><router-link to="/service-details">Data Visualization</router-link></h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod ut labore et dolore magna aliqua.</p>
                            <router-link to="/service-details" class="learn-more-btn"><vue-feather type="arrow-right"></vue-feather>Learn More</router-link>
                            <div class="shape"><img src="../../../assets/img/bigdata-analytics/rectangle.png" alt="image"></div>
                        </div>
                    </div>
                    
                    <div class="col-lg-4 col-md-6 col-sm-12">
                        <div class="single-services-box-item">
                            <div class="icon">
                                <img src="../../../assets/img/bigdata-analytics/icon5.png" alt="image">
                            </div>
                            <h3><router-link to="/service-details">Securities Research</router-link></h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod ut labore et dolore magna aliqua.</p>
                            <router-link to="/service-details" class="learn-more-btn"><vue-feather type="arrow-right"></vue-feather>Learn More</router-link>
                            <div class="shape"><img src="../../../assets/img/bigdata-analytics/rectangle.png" alt="image"></div>
                        </div>
                    </div>
                    
                    <div class="col-lg-4 col-md-6 col-sm-12">
                        <div class="single-services-box-item">
                            <div class="icon">
                                <img src="../../../assets/img/bigdata-analytics/icon6.png" alt="image">
                            </div>
                            <h3><router-link to="/service-details">Data Governance</router-link></h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod ut labore et dolore magna aliqua.</p>
                            <router-link to="/service-details" class="learn-more-btn"><vue-feather type="arrow-right"></vue-feather>Learn More</router-link>
                            <div class="shape"><img src="../../../assets/img/bigdata-analytics/rectangle.png" alt="image"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</template>

<script>
export default {
    name: 'OurServices'
}
</script>