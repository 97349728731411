<template>
  <div>
    <!-- Start Page Title -->
    <div class="page-title-area">
      <div class="d-table">
        <div class="d-table-cell">
          <div class="container">
            <h2>Features</h2>
          </div>
        </div>
      </div>

      <div class="shape1">
        <img src="../../../assets/img/shape1.png" alt="shape" />
      </div>
      <div class="shape2 rotateme">
        <img src="../../../assets/img/shape2.svg" alt="shape" />
      </div>
      <div class="shape3">
        <img src="../../../assets/img/shape3.svg" alt="shape" />
      </div>
      <div class="shape4">
        <img src="../../../assets/img/shape4.svg" alt="shape" />
      </div>
      <div class="shape5">
        <img src="../../../assets/img/shape5.png" alt="shape" />
      </div>
      <div class="shape6 rotateme">
        <img src="../../../assets/img/shape4.svg" alt="shape" />
      </div>
      <div class="shape7">
        <img src="../../../assets/img/shape4.svg" alt="shape" />
      </div>
      <div class="shape8 rotateme">
        <img src="../../../assets/img/shape2.svg" alt="shape" />
      </div>
    </div>
    <!-- End Page Title -->

    <!-- Start Features Area -->
    <div class="features-area ptb-80 bg-f9f6f6">
      <div class="container">
        <div class="section-title">
          <h2>Our Features</h2>
          <div class="bar"></div>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </p>
        </div>

        <div class="row">
          <div class="col-lg-6 col-md-6 col-sm-6">
            <div class="single-features">
              <div class="icon">
                <vue-feather type="settings"></vue-feather>
              </div>

              <h3><a href="#">Incredible Infrastructure</a></h3>
              <p>
                Lorem ipsum dolor amet, adipiscing, sed do eiusmod tempor
                incididunt ut labore dolore magna aliqua.
              </p>
            </div>
          </div>

          <div class="col-lg-6 col-md-6 col-sm-6">
            <div class="single-features">
              <div class="icon">
                <vue-feather type="mail"></vue-feather>
              </div>

              <h3><a href="#">Email Notifications</a></h3>
              <p>
                Lorem ipsum dolor amet, adipiscing, sed do eiusmod tempor
                incididunt ut labore dolore magna aliqua.
              </p>
            </div>
          </div>

          <div class="col-lg-6 col-md-6 col-sm-6">
            <div class="single-features">
              <div class="icon bg-c679e3">
                <vue-feather type="grid"></vue-feather>
              </div>

              <h3><a href="#">Simple Dashboard</a></h3>
              <p>
                Lorem ipsum dolor amet, adipiscing, sed do eiusmod tempor
                incididunt ut labore dolore magna aliqua.
              </p>
            </div>
          </div>

          <div class="col-lg-6 col-md-6 col-sm-6">
            <div class="single-features">
              <div class="icon bg-c679e3">
                <vue-feather type="info"></vue-feather>
              </div>

              <h3><a href="#">Information Retrieval</a></h3>
              <p>
                Lorem ipsum dolor amet, adipiscing, sed do eiusmod tempor
                incididunt ut labore dolore magna aliqua.
              </p>
            </div>
          </div>

          <div class="col-lg-6 col-md-6 col-sm-6">
            <div class="single-features">
              <div class="icon bg-eb6b3d">
                <vue-feather type="mouse-pointer"></vue-feather>
              </div>

              <h3><a href="#">Drag and Drop Functionality</a></h3>
              <p>
                Lorem ipsum dolor amet, adipiscing, sed do eiusmod tempor
                incididunt ut labore dolore magna aliqua.
              </p>
            </div>
          </div>

          <div class="col-lg-6 col-md-6 col-sm-6">
            <div class="single-features">
              <div class="icon bg-eb6b3d">
                <vue-feather type="bell"></vue-feather>
              </div>

              <h3><a href="#">Deadline Reminders</a></h3>
              <p>
                Lorem ipsum dolor amet, adipiscing, sed do eiusmod tempor
                incididunt ut labore dolore magna aliqua.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Features Area -->

    <!-- Start Features Area -->
    <div class="features-area-two ptb-80 bg-f7fafd">
      <div class="container">
        <div class="row h-100 justify-content-center align-items-center">
          <div class="col-lg-6 col-md-12 services-content">
            <div class="section-title">
              <h2>Experience The Best Features Ever</h2>
              <div class="bar"></div>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
            </div>

            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="box">
                  <vue-feather type="lock"></vue-feather> Secure & Fast
                </div>
              </div>

              <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="box">
                  <vue-feather type="watch"></vue-feather> Rich Experience
                </div>
              </div>

              <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="box">
                  <vue-feather type="users"></vue-feather> Support Stuffs
                </div>
              </div>

              <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="box">
                  <vue-feather type="trending-up"></vue-feather> Forex Trading
                </div>
              </div>

              <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="box">
                  <vue-feather type="folder"></vue-feather> File Backups
                </div>
              </div>

              <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="box">
                  <vue-feather type="monitor"></vue-feather> Remote Desktop
                </div>
              </div>

              <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="box">
                  <vue-feather type="mail"></vue-feather> Email Servers
                </div>
              </div>

              <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="box">
                  <vue-feather type="cloud"></vue-feather> Hybrid Cloud
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-md-12 services-right-image">
            <img
              src="../../../assets/img/services-right-image/book-self.png"
              alt="book-self"
            />
            <img
              src="../../../assets/img/services-right-image/box.png"
              alt="box"
            />
            <img
              src="../../../assets/img/services-right-image/chair.png"
              alt="chair"
            />
            <img
              src="../../../assets/img/services-right-image/cloud.png"
              alt="cloud"
            />
            <img
              src="../../../assets/img/services-right-image/cup.png"
              alt="cup"
            />
            <img
              src="../../../assets/img/services-right-image/flower-top.png"
              alt="flower"
            />
            <img
              src="../../../assets/img/services-right-image/head-phone.png"
              alt="head-phone"
            />
            <img
              src="../../../assets/img/services-right-image/monitor.png"
              alt="monitor"
            />
            <img
              src="../../../assets/img/services-right-image/mug.png"
              alt="mug"
            />
            <img
              src="../../../assets/img/services-right-image/table.png"
              alt="table"
            />
            <img
              src="../../../assets/img/services-right-image/tissue.png"
              alt="tissue"
            />
            <img
              src="../../../assets/img/services-right-image/water-bottle.png"
              alt="water-bottle"
            />
            <img
              src="../../../assets/img/services-right-image/wifi.png"
              alt="wifi"
            />
            <img
              src="../../../assets/img/services-right-image/cercle-shape.png"
              class="bg-image rotateme"
              alt="shape"
            />

            <img
              src="../../../assets/img/services-right-image/main-pic.png"
              alt="main-pic"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- End Features Area -->

    <!-- Start Features Area -->
    <div class="features-area-two ptb-80">
      <div class="container">
        <div class="row h-100 justify-content-center align-items-center">
          <div class="col-lg-6 col-md-12 services-left-image">
            <img
              src="../../../assets/img/services-left-image/big-monitor.png"
              alt="big-monitor"
            />
            <img
              src="../../../assets/img/services-left-image/creative.png"
              alt="creative"
            />
            <img
              src="../../../assets/img/services-left-image/developer.png"
              alt="developer"
            />
            <img
              src="../../../assets/img/services-left-image/flower-top.png"
              alt="flower-top"
            />
            <img
              src="../../../assets/img/services-left-image/small-monitor.png"
              alt="small-monitor"
            />
            <img
              src="../../../assets/img/services-left-image/small-top.png"
              alt="small-top"
            />
            <img
              src="../../../assets/img/services-left-image/table.png"
              alt="table"
            />
            <img
              src="../../../assets/img/services-left-image/target.png"
              alt="target"
            />
            <img
              src="../../../assets/img/services-left-image/cercle-shape.png"
              class="bg-image rotateme"
              alt="shape"
            />

            <img
              src="../../../assets/img/services-left-image/main-pic.png"
              alt="main-pic"
            />
          </div>

          <div class="col-lg-6 col-md-12 services-content">
            <div class="section-title">
              <h2>What Our Software Can Do For You</h2>
              <div class="bar"></div>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
            </div>

            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="box">
                  <vue-feather type="layout"></vue-feather> Responsive Design
                </div>
              </div>

              <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="box">
                  <vue-feather type="code"></vue-feather> React Web Development
                </div>
              </div>

              <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="box">
                  <vue-feather type="smartphone"></vue-feather> Android Apps
                  Development
                </div>
              </div>

              <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="box">
                  <vue-feather type="code"></vue-feather> Laravel Web
                  Development
                </div>
              </div>

              <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="box">
                  <vue-feather type="smartphone"></vue-feather> iOS Apps
                  Development
                </div>
              </div>

              <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="box">
                  <vue-feather type="pen-tool"></vue-feather> UX/UI Design
                </div>
              </div>

              <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="box">
                  <vue-feather type="shopping-cart"></vue-feather> E-commerce
                  Development
                </div>
              </div>

              <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="box">
                  <vue-feather type="check-circle"></vue-feather> Print Ready
                  Design
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Start Features Area -->
  </div>
</template>

<script>
export default {
  name: "FeaturesPage",
};
</script>