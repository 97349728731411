<template>
  <div class="tw-z-10" :class="getSectionType()">
    <div class="d-flex gap-3">
      <div class="text-start flex-grow-1">
        <h3 class="m-0 d-flex">
          <slot name="title"></slot>
        </h3>
        <p :class="getBodySize()">
          <slot name="body"></slot>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardWithLineGeneric",
  props: {
    type: {
      type: String,
      default: "normal",
    },
    bodySize: {
      type: String,
      default: "m",
    },
  },
  methods: {
    getSectionType() {
      const sectionTypeClassMap = {
        normal: "single-hosting-features",
        dashed: "single-box",
      };
      return sectionTypeClassMap[this.type] ?? sectionTypeClassMap["normal"];
    },
    getBodySize() {
      const bodySizeClassMap = {
        s: "p-2",
        m: "p-4",
        l: "tw-p-6",
      };
      return bodySizeClassMap[this.bodySize] ?? bodySizeClassMap["m"];
    },
  },
};
</script>
