<template>
  <div id="header" :class="['headroom', { 'is-sticky': isSticky }]">
    <div class="startp-nav">
      <div class="container">
        <nav class="navbar navbar-expand-md navbar-light">
          <router-link class="navbar-brand" to="/">
            <img
              src="../../assets/img/logo-wdata-transparent.png"
              class="logo-img"
              alt="logo"
            />
          </router-link>

          <div
            class="navbar-toggler"
            @click="active = !active"
            :aria-pressed="active ? 'true' : 'false'"
            v-bind:class="{ active: button_active_state }"
            v-on:click="button_active_state = !button_active_state"
          >
            <span class="icon-bar top-bar"></span>
            <span class="icon-bar middle-bar"></span>
            <span class="icon-bar bottom-bar"></span>
          </div>

          <div class="collapse navbar-collapse" :class="{ show: active }">
            <ul class="navbar-nav nav ml-auto">
              <template v-for="(elt, ind) in menu">
                <MenuHeaderMultiBtn
                  v-if="elt.subElements"
                  v-bind:key="ind + 'multi_btn'"
                  :class="{ 'd-block d-md-none': elt.onlyMobile }"
                  @hide-menu="hideMenu"
                  :menuElt="elt"
                />
                <MenuHeaderSingleBtn
                  v-bind:key="ind + 'single_btn'"
                  :class="{ 'd-block d-md-none': elt.onlyMobile }"
                  :menuElt="elt"
                  @hide-menu="hideMenu"
                  v-else
                />
              </template>
            </ul>
          </div>

          <div class="others-option">
            <!-- a :href="BACKOFFICE_URL">
              <ButtonComponent class="tw-text-uppercase" size="l">
                Espace collaborateur
              </ButtonComponent>
            </a -->
          </div>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import MenuHeaderSingleBtn from "./MenuHeaderButtons/MenuHeaderSingleBtn/MenuHeaderSingleBtn.vue";
import MenuHeaderMultiBtn from "./MenuHeaderButtons/MenuHeaderMultiBtn/MenuHeaderMultiBtn.vue";
/* import ButtonComponent from "../generics/Buttons/ButtonComponent.vue"; */
import { BACKOFFICE_URL } from "../../statics/app_static";
export default {
  name: "MainHeader",
  components: {
    MenuHeaderSingleBtn,
    MenuHeaderMultiBtn,
    /*  ButtonComponent, */
  },
  data() {
    return {
      isSticky: false,
      active: false,
      button_active_state: false,
      BACKOFFICE_URL,
      menu: [
        {
          name: "Société",
          link: "/",
        },
        {
          name: "Nos expertises",
          link: "/nos-expertises",
        },
        {
          name: "Engagements RSE",
          link: "/engagement-rse",
        },
        {
          name: "Carrières",
          subElements: [
            {
              name: "Rejoignez nous !",
              link: "/join-us",
            },
            {
              name: "Nos offres d'emploi",
              link: "/nos-offres",
            },
          ],
        },
        {
          name: "Contact",
          link: "/contact",
        },
        {
          name: "Espace collaborateur",
          link: "/login",
          onlyMobile: true,
        },
      ],
    };
  },
  mounted() {
    const that = this;
    window.addEventListener("scroll", () => {
      let scrollPos = window.scrollY;
      // eslint-disable-next-line no-console
      if (scrollPos >= 100) {
        that.isSticky = true;
      } else {
        that.isSticky = false;
      }
    });
  },
  methods: {
    hideMenu() {
      this.button_active_state = false;
      this.active = false;
    },
  },
};
</script>
