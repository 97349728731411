<template>
  <div class="pt-5">
    <SimpleSectionHeader
      title="POLITIQUE DE CONFIDENTIALITÉ"
      class="pb-0 tw-pt-30"
      isSectionTitle
    />
    <div class="container section-politiques">
      <CardWithLineGeneric>
        <template v-slot:title>
          <vue-feather type="check" class="tw-text-orange-500 me-3" />
          Préambule.
        </template>
        <template v-slot:body
          >La présente "Politique de Protection des Données Personnelles" (la
          "Politique") a pour objet d’informer toutes les personnes physiques
          concernées ("Vous" ou "Votre/Vos") sur la manière dont
          {{ APP_NAME }} collecte et utilise de telles Données Personnelles et
          sur les moyens dont Vous disposez pour contrôler cette
          utilisation.</template
        >
      </CardWithLineGeneric>

      <CardWithLineGeneric>
        <template v-slot:title>
          <vue-feather type="check" class="tw-text-orange-500 me-3" />
          Champ d’application de la présente politique de protection des données
          personnelles.
        </template>
        <template v-slot:body>
          <p>
            La présente Politique énonce les principes et lignes directrices
            pour la protection de Vos Données Personnelles, ce qui inclut les
            Données Personnelles collectées sur - ou via - les sites.
          </p>
          <p>
            {{ APP_NAME }} collecte des Données Personnelles en ligne (y compris
            par e-mail) ou hors connexion ; la présente Politique s’applique
            quel que soit le mode de collecte ou de traitement.
          </p>
          <p>
            La notion de données personnelles (les « Données Personnelles »)
            désigne toute information relative à une personne physique
            identifiée ou identifiable. Une personne est « identifiable » dès
            lors qu’elle peut être identifiée, directement ou indirectement, en
            particulier par référence à un numéro d’identification ou à un ou
            plusieurs éléments qui lui sont propres.
          </p>
          <p>
            Les « Données Non-Personnelles » correspondent à des informations ne
            permettant pas d’identifier une personne.
          </p>
          <p>
            Note à l’attention des visiteurs et utilisateurs des Sites : chaque
            Site est soumis à des Conditions d’Utilisation spécifiques
            disponibles sur chacun des Sites et qui font partie de la présente
            Politique. Votre utilisation des Sites ainsi que les Données
            Personnelles que Vous accepterez de communiquer sur les Sites sont
            soumises aux dispositions de la présente Politique et aux Conditions
            d’Utilisation applicables.
          </p>
        </template>
      </CardWithLineGeneric>

      <CardWithLineGeneric>
        <template v-slot:title>
          <vue-feather type="check" class="tw-text-orange-500 me-3" />
          Données collectées.
        </template>
        <template v-slot:body>
          <p>Les Données Personnelles peuvent inclure ce qui suit :</p>
          <ul>
            <li>&nbsp;Fonctions ;</li>
            <li>&nbsp;prénom et nom ;</li>
            <li>adresse postale ;</li>
            <li>adresse électronique ;</li>
            <li>numéro(s) de téléphone ;</li>
            <li>login et mot de passe ;</li>
            <li>université, diplôme et cours suivis ;</li>
            <li>
              type d’organisation (société, école, université, etc.), nom de
              l’organisation, taille de l’organisation, service et intitulé du
              poste ;
            </li>
            <li>industrie et sous-secteurs industriels ;</li>
            <li>
              mode de livraison et personnes auxquelles les achats doivent être
              expédiés ;
            </li>
            <li>
              données financières (numéro de carte de crédit et date
              d’expiration, nom et adresse du titulaire de la carte) ;
            </li>
            <li>adresse IP, et</li>
            <li>
              toute autre Donnée Personnelle pouvant s’avérer pertinente aux
              fins énoncées ci-après.
            </li>
          </ul>
          <p>
            En tout état de cause, les Données Personnelles collectées se
            limiteront toutefois aux données nécessaires aux fins exposées à
            l’Article 4 ci-après.
          </p>
          <p>
            Note à l’attention des visiteurs et utilisateurs des Sites :
            certaines fonctionnalités et caractéristiques du Site, ne peuvent
            être utilisées que si Vous communiquez à {{ APP_NAME }} certaines
            Données Personnelles lorsque Vous visitez ou utilisez le Site. Vous
            êtes libre de fournir ou non tout ou partie de Vos Données
            Personnelles. Toutefois, si Vous choisissez de ne pas les fournir,
            une telle décision pourrait empêcher la réalisation ou la
            réalisation satisfaisante des objectifs décrits à l’Article 4
            ci-après, certains services et certaines fonctionnalités des Sites
            pourraient ne pas fonctionner correctement et/ou Vous vous verrez
            refuser l’accès à certaines pages du Site. En particulier, Vous ne
            serez pas autorisé à acheter des licences logicielles ou d’autres
            produits ou services via le Site.
          </p>
        </template>
      </CardWithLineGeneric>

      <CardWithLineGeneric>
        <template v-slot:title>
          <vue-feather type="check" class="tw-text-orange-500 me-3" />
          Finalités de la collecte des données personnelles.
        </template>
        <template v-slot:body>
          <p>
            Comme indiqué ci-dessus, Vous fournissez Vos Données Personnelles de
            manière volontaire. La base légale des traitements effectués est le
            consentement.&nbsp;
          </p>

          <p>
            Les Données Personnelles sont généralement collectées pour les
            besoins de l’activité de {{ APP_NAME }} tels que l’amélioration des
            efforts marketing et publicitaires de {{ APP_NAME }}, une meilleure
            adaptation des produits et services de {{ APP_NAME }} aux besoins
            des clients ou le respect des obligations déclaratives prévues par
            la loi, et d’autres activités similaires.
          </p>
          <p>
            Les données sont conservées 3 ans à compter de leur collecte par le
            responsable de traitement ou du dernier contact émanant du
            prospect.&nbsp;
          </p>
          <p>
            {{ APP_NAME }} collecte et utilise les Données Personnelles Vous
            concernant pour les besoins de son activité et notamment aux fins
            suivantes :
          </p>
          <ul>
            <li>
              Vous permettre de demander et d’obtenir des informations sur
              {{ APP_NAME }} et les produits et services de {{ APP_NAME }} ;
            </li>
            <li>
              Vous permettre une utilisation interactive et personnalisée des
              Sites ;
            </li>
            <li>
              Cerner Vos besoins et centres d’intérêt et pour Vous fournir les
              produits et/services les mieux adaptés ;
            </li>
            <li>
              Vous permettre d’ouvrir et de gérer un compte afin d’obtenir de la
              documentation spécialisée et une assistance technique ;
            </li>
            <li>
              Accéder à l’ensemble des caractéristiques et options offertes par
              les Sites ;
            </li>
            <li>
              Vous permettre d’échanger avec les autres utilisateurs des Sites ;
            </li>
            <li>Vous permettre de participer à un concours ;</li>
            <li>
              Permettre à {{ APP_NAME }} de gérer ses activités marketing ;
            </li>
            <li>
              Permettre à {{ APP_NAME }} de gérer ses relations commerciales :
              opportunités, commerciales, offres commerciales, achats, contrats,
              commandes, factures, etc ;
            </li>
            <li>
              Vous proposer des services de type commercial ou de support ;
            </li>
            <li>
              Vous permettre d’acheter des licences logicielles, produits ou
              services, de télécharger des logiciels et/ou d’enregistrer des
              licences logicielles ;
            </li>
            <li>
              Vous permettre de Vous inscrire à des séminaires, webinaires ou
              évènements ;
            </li>
            <li>
              Permettre à {{ APP_NAME }} de gérer ses activités de Recherche et
              Développement : demandes de développement, incidents, les
              questions de qualité, tests, etc. ;
            </li>
            <li>
              Traiter les candidatures et gérer les sources de recrutement, dans
              ce cas
              <router-link to="/politiques-rgpd">
                <strong>accédez-ici</strong>
              </router-link>
              au détail du traitement réalisé spécifiquement.&nbsp;&nbsp;<em
                >&nbsp;</em
              >
            </li>
            <li>
              Gérer Votre formation et/ou certification sur les produits et
              services de {{ APP_NAME }}.
            </li>
          </ul>
        </template>
      </CardWithLineGeneric>

      <CardWithLineGeneric>
        <template v-slot:title>
          <vue-feather type="check" class="tw-text-orange-500 me-3" />
          Cookies.
        </template>
        <template v-slot:body>
          <p>
            Lorsque vous naviguez sur les Sites, les cookies sont activés par
            défaut et les données peuvent être lues ou conservées localement sur
            votre matériel. Vous serez averti la première fois que Vous recevez
            un cookie et Vous pourrez ainsi décider de l’accepter ou de le
            refuser. En l'acceptant, Vous acceptez expressément l’utilisation
            par {{ APP_NAME }} de tels cookies.
          </p>
        </template>
      </CardWithLineGeneric>

      <CardWithLineGeneric>
        <template v-slot:title>
          <vue-feather type="check" class="tw-text-orange-500 me-3" />
          Transfert de données personnelles.
        </template>
        <template v-slot:body>
          <p>
            Vos Données Personnelles peuvent être divulguées au sein de
            {{ APP_NAME }}
            aux fins énoncées dans la présente Politique.
          </p>
          <p>
            {{ APP_NAME }} peut transférer Vos Données Personnelles en dehors de
            l’Union européenne, à condition de s’assurer, avant de les
            transférer, que les entités extérieures à l’Union européenne,
            offrent un niveau de protection adéquat, conformément à la
            législation européenne en vigueur.
          </p>
          <p>
            Si {{ APP_NAME }} apprenait qu’un tiers auquel {{ APP_NAME }} a
            communiqué des Données Personnelles aux fins énoncées ci-dessus,
            utilise ou divulgue des Données Personnelles sans respecter la
            présente Politique ou en violation de la législation applicable,
            {{ APP_NAME }} prendrait toute mesure raisonnable pour prévenir ou
            mettre fin à une telle utilisation ou divulgation.
          </p>
        </template>
      </CardWithLineGeneric>

      <CardWithLineGeneric>
        <template v-slot:title>
          <vue-feather type="check" class="tw-text-orange-500 me-3" />
          Conditions de traitement et de stockage des données personnelles.
        </template>
        <template v-slot:body>
          <p>
            Le « traitement » des Données Personnelles inclut notamment
            l’utilisation, la conservation, l’enregistrement, le transfert,
            l’adaptation, l’analyse, la modification, la déclaration, le partage
            et la destruction des Données Personnelles en fonction de ce qui est
            nécessaire au regard des circonstances ou des exigences légales.
          </p>
          <p>
            Toutes les Données Personnelles collectées sont conservées pour une
            durée limitée en fonction de la finalité du traitement et uniquement
            pour la durée prévue par la législation applicable.
          </p>
        </template>
      </CardWithLineGeneric>

      <CardWithLineGeneric>
        <template v-slot:title>
          <vue-feather type="check" class="tw-text-orange-500 me-3" />
          Liens vers des sites web non contrôlés par {{ APP_NAME }}.
        </template>
        <template v-slot:body>
          <p>
            Les Sites peuvent proposer des liens vers des sites Internet de
            tiers susceptibles de Vous intéresser.
          </p>
          <p>
            {{ APP_NAME }} n'exerce aucun contrôle sur le contenu de sites de
            tiers ou sur les pratiques de ces tiers en matière de protection des
            Données Personnelles qu'ils pourraient recueillir. En conséquence,
            {{ APP_NAME }}
            décline toute responsabilité concernant le traitement par ces tiers
            de Vos Données Personnelles. Il est de votre responsabilité de Vous
            renseigner sur les politiques de protection des données personnelles
            de ces tiers.
          </p>
        </template>
      </CardWithLineGeneric>

      <CardWithLineGeneric>
        <template v-slot:title>
          <vue-feather type="check" class="tw-text-orange-500 me-3" />
          Transfert de données personnelles.
        </template>
        <template v-slot:body>
          <p>
            En cas d’accès aux Sites depuis un pays non membre de l’Union
            européenne dont la législation relative à la collecte, à
            l’utilisation et au transfert de données diffère de celles de
            l’Union européenne, nous attirons votre attention sur le fait qu’en
            continuant d’utiliser les Sites, qui sont régis par le droit
            français, les Conditions d’utilisation correspondantes et la
            présente Politique, Vous transférez Vos Données Personnelles vers
            l’Union européenne et consentez à ce transfert.
          </p>
          <p>
            Vos Données Personnelles peuvent être divulguées au sein de
            {{ APP_NAME }}
            aux fins énoncées à l’Article 4 de la présente Politique.
          </p>
          <p>
            {{ APP_NAME }} peut transférer Vos Données Personnelles en dehors de
            l’Union européenne, à condition de s’assurer, avant de les
            transférer, que les entités extérieures à l’Union européenne,
            offrent un niveau de protection adéquat, conformément à la
            législation européenne en vigueur.
          </p>
          <p>
            Si {{ APP_NAME }} apprenait qu’un tiers auquel {{ APP_NAME }} a
            communiqué des Données Personnelles aux fins énoncées à l’Article 4
            ci-dessus, utilise ou divulgue des Données Personnelles sans
            respecter la présente Politique ou en violation de la législation
            applicable, {{ APP_NAME }}
            prendrait toute mesure raisonnable pour prévenir ou mettre fin à une
            telle utilisation ou divulgation.
          </p>
          <p>
            Vous disposez également du droit d’autoriser ou non {{ APP_NAME }} à
            utiliser Vos Données Personnelles pour une finalité autre que celles
            pour lesquelles ces Données Personnelles ont initialement été
            collectées. Vous pouvez vous opposer à cet usage en vous adressant à
            l’adresse suivante : {{ APP_EMAIL }}
          </p>
          <p>
            {{ APP_NAME }} peut également être amené à transférer Vos Données
            Personnelles à des tiers si {{ APP_NAME }} estime qu’un tel
            transfert est nécessaire pour des raisons techniques (par exemple,
            pour des services d’hébergement par un tiers) ou pour protéger ses
            intérêts légaux (par exemple, en cas de vente d’actifs à une société
            tiers, de changement de contrôle ou de liquidation totale ou
            partielle de {{ APP_NAME }}).
          </p>
          <p>
            En outre, {{ APP_NAME }} peut partager Vos Données Personnelles au
            revendeur dont Vous dépendez ou affecté au territoire dans lequel
            Vous vous trouvez pour les besoins de l’activité de {{ APP_NAME }}.
            {{ APP_NAME }} peut également partager vos Données Personnelles avec
            des partenaires commerciaux lorsqu’{{ APP_NAME }} et le partenaire
            commercial sponsorisent conjointement un évènement ou participent
            ensemble à une promotion marketing dans laquelle Vous vous engagez.
          </p>
          <p>
            {{ APP_NAME }} peut communiquer Vos Données Personnelles si la loi
            l’y oblige ou si {{ APP_NAME }} estime de toute bonne foi qu’une
            telle divulgation est raisonnablement nécessaire pour se conformer à
            une procédure légale (par exemple, un mandat, une citation à
            comparaître ou toute autre décision de justice) ou pour protéger les
            droits, les biens ou la sécurité personnelle de Groupe
            {{ APP_NAME }}, de nos clients ou du public.
          </p>
          <p>
            Si la législation applicable le permet, {{ APP_NAME }} peut
            également partager Vos Données Personnelles avec des tiers afin de
            Vous proposer des offres marketing ou publicitaires ciblées.
          </p>
          <p>
            Ces transferts pourront avoir lieu par Internet ou par toute autre
            méthode jugée opportune par {{ APP_NAME }} conformément à la
            législation applicable et aux politiques internes de sécurité de
            Groupe {{ APP_NAME }}.
          </p>
        </template>
      </CardWithLineGeneric>

      <CardWithLineGeneric>
        <template v-slot:title>
          <vue-feather type="check" class="tw-text-orange-500 me-3" />
          Vos droits : Votre droit d’accès et à la rectification de vos données.
        </template>
        <template v-slot:body>
          <p>
            Vous disposez du droit d’accéder et de faire rectifier vos données
            personnelles. Vous pouvez nous demander que vos données personnelles
            soient, selon les cas, rectifiées, complétées si elles sont
            inexactes, incomplètes, équivoques, périmées. Votre droit à
            l’effacement de vos données Vous pouvez nous demander l’effacement
            de vos données personnelles lorsque l’un des motifs suivants
            s’applique :
          </p>
          <ul>
            <li>
              les données personnelles ne sont plus nécessaires au regard des
              finalités pour lesquelles elles ont été collectées ou traitées
              d’une autre manière ;
            </li>
            <li>vous retirez le consentement préalablement donné ;</li>
            <li>
              vous vous opposez au traitement de vos données personnelles pour
              motif légitime ;
            </li>
            <li>
              le traitement de données personnelles n’est pas conforme aux
              dispositions de la législation et de la réglementation applicable.
            </li>
          </ul>
          <p>
            Néanmoins, l’exercice de ce droit ne sera pas possible lorsque la
            conservation de vos données personnelles est nécessaire au regard de
            la législation ou de la réglementation et notamment par exemple pour
            la constatation, l’exercice ou la défense de droits en justice.
          </p>
        </template>
      </CardWithLineGeneric>

      <CardWithLineGeneric>
        <template v-slot:title>
          <vue-feather type="check" class="tw-text-orange-500 me-3" />
          Votre droit à la limitation des traitements de données.
        </template>
        <template v-slot:body>
          <p>
            Vous pouvez demander la limitation du traitement de vos données
            personnelles dans les cas prévus par la législation et la
            réglementation. Votre droit de vous opposer aux traitements de
            données Vous avez le droit de vous opposer à un traitement de
            données personnelles vous concernant lorsque le traitement est fondé
            sur l’intérêt légitime du responsable du traitement.
          </p>
        </template>
      </CardWithLineGeneric>

      <CardWithLineGeneric>
        <template v-slot:title>
          <vue-feather type="check" class="tw-text-orange-500 me-3" />
          Votre droit à la portabilité de vos données.
        </template>
        <template v-slot:body>
          <p>
            Depuis le 25 mai 2018, vous disposerez du droit à la portabilité de
            vos données personnelles. Les données sur lesquelles peut s’exercer
            ce droit sont :
          </p>
          <ul>
            <li>
              uniquement vos données personnelles, ce qui exclut les données
              personnelles anonymisées ou les données qui ne vous concernent pas
              ;
            </li>
            <li>
              les données personnelles déclaratives ainsi que les données
              personnelles de fonctionnement évoquées précédemment ;
            </li>
            <li>
              les données personnelles qui ne portent pas atteinte aux droits et
              libertés de tiers telles que celles protégées par le secret des
              affaires.
            </li>
          </ul>
          <p>
            Ce droit est limité aux traitements basés sur le consentement ou sur
            un contrat ainsi qu’aux données personnelles que vous avez
            personnellement générées.
          </p>
          <p>
            Ce droit n’inclut ni les données dérivées ni les données inférées,
            qui sont des données personnelles créées par
            {{ APP_NAME }}.<br />Votre droit de retirer votre consentement.
          </p>
          <p>
            Lorsque les traitements de données que nous mettons en œuvre sont
            fondés sur votre consentement, vous pouvez le retirer à n’importe
            quel moment. Nous cessons alors de traiter vos données à caractère
            personnel sans que les opérations antérieures pour lesquelles vous
            aviez consenti ne soient remises en cause.<br />&nbsp;
          </p>
        </template>
      </CardWithLineGeneric>
      <CardWithLineGeneric>
        <template v-slot:title>
          <vue-feather type="check" class="tw-text-orange-500 me-3" />
          Votre droit d’introduire un recours.
        </template>
        <template v-slot:body>
          <p>
            Vous avez le droit d’introduire une réclamation auprès de la Cnil
            sur le territoire français et ce sans préjudice de tout autre
            recours administratif ou juridictionnel. Votre droit de définir des
            directives post-mortem Vous avez la possibilité de définir des
            directives relatives à la conservation, à l’effacement et à la
            communication de vos données personnelles après votre décès et ce
            auprès d’un tiers de confiance, certifié et chargé de faire
            respecter la volonté du défunt conformément aux exigences du cadre
            juridique applicable.
          </p>
        </template>
      </CardWithLineGeneric>

      <CardWithLineGeneric>
        <template v-slot:title>
          <vue-feather type="check" class="tw-text-orange-500 me-3" />
          Les modalités d’exercice de vos droits.
        </template>
        <template v-slot:body>
          <p>
            Tous les droits énumérés ci-avant peuvent être exercés à l’adresse
            e-mail suivante
            <a :href="'mailto:' + APP_EMAIL + '?subject=GDPR'"
              ><u>{{ APP_EMAIL }}</u></a
            >
            ou par courrier accompagné d’une copie d’un document d’identité en
            s’adressant au DPO de {{ APP_NAME }} à l’adresse suivante 28 rue
            Jacques Ibert 92 Levallois-Perret.
          </p>
          <p>
            Si vous disposez d’un compte, vous pouvez exercer Vos droits d’accès
            et de rectification en vous connectant à votre compte.<br />Néanmoins,
            en ce qui concerne l’exercice du droit à l’information, nous pouvons
            ne pas avoir l’obligation d’y donner suite si vous disposez déjà de
            cette information.
          </p>
          <ul>
            <li>
              l’enregistrement ou la communication de vos données personnelles
              sont expressément prévus par la loi ;
            </li>
            <li>la communication d’informations se révèle impossible ;</li>
            <li>
              la communication d’informations exigerait des efforts
              disproportionnés.
            </li>
          </ul>
        </template>
      </CardWithLineGeneric>

      <CardWithLineGeneric>
        <template v-slot:title>
          <vue-feather type="check" class="tw-text-orange-500 me-3" />
          Sécurité et destinataires des données.
        </template>
        <template v-slot:body>
          <p>
            {{ APP_NAME }} veille à protéger et sécuriser les Données
            Personnelles que vous avez choisi de lui communiquer, afin d’assurer
            leur confidentialité et empêcher qu’elles ne soient déformées,
            endommagées, détruites ou divulguées à des tiers non autorisés.
          </p>

          <p>
            {{ APP_NAME }} a pris des mesures de protection physiques,
            électroniques et organisationnelles pour prévenir toute perte,
            mauvaise utilisation, accès ou diffusion non autorisé, altération ou
            destruction éventuelle de ces Données Personnelles. Parmi ces
            mesures de protection, {{ APP_NAME }} intègre des technologies
            spécialement conçues pour protéger les Données Personnelles durant
            leur transfert. Toutefois, malgré les efforts de {{ APP_NAME }} pour
            protéger Vos Données Personnelles, {{ APP_NAME }} ne peut pas
            garantir l’infaillibilité de cette protection en raison de risques
            inévitables pouvant survenir lors de la transmission de Données
            Personnelles.
          </p>
          <p>
            Toutes les Données Personnelles étant confidentielles, leur accès
            est limité aux collaborateurs et prestataires&nbsp;
            {{ APP_NAME }} qui en ont besoin dans le cadre de l’exécution de
            leur mission. Toutes les personnes ayant accès à Vos Données
            Personnelles sont tenues par une obligation de confidentialité et
            s’exposent à des mesures disciplinaires et/ou autres sanctions si
            elles ne respectent pas ces obligations.
          </p>
          <p>
            Toutefois, il importe que Vous fassiez preuve de prudence pour
            empêcher tout accès non autorisé à Vos Données Personnelles. Vous
            êtes responsable de la confidentialité de Votre mot de passe et des
            informations figurant dans Votre compte. Par conséquent, Vous devez
            Vous assurer de fermer Votre session en cas d’utilisation partagée
            d’un même ordinateur.
          </p>
        </template>
      </CardWithLineGeneric>

      <CardWithLineGeneric>
        <template v-slot:title>
          <vue-feather type="check" class="tw-text-orange-500 me-3" />
          Résolution des conflits.
        </template>
        <template v-slot:body>
          <p>
            Bien qu’{{ APP_NAME }} ait pris des mesures raisonnables pour
            protéger les Données Personnelles, aucune technologie de
            transmission ou de stockage n’est totalement infaillible.
          </p>
          <p>
            Toutefois, {{ APP_NAME }} est soucieuse de garantir la protection
            des Données Personnelles. Si Vous avez des raisons de penser que la
            sécurité de Vos Données Personnelles a été compromise ou qu’elles
            ont fait l’objet d’une utilisation abusive, Vous êtes invité à
            contacter {{ APP_NAME }}
            à l’adresse suivante :
            <a
              :href="
                'mailto:' +
                APP_EMAIL +
                '?subject=Politique%20de%20protection%20des%20donn%C3%A9es%20personnelles'
              "
              ><u>{{ APP_EMAIL }}</u></a
            >
          </p>
          <p>
            {{ APP_NAME }} instruira les réclamations concernant l’utilisation
            et la divulgation de Données Personnelles et tentera de les résoudre
            conformément aux principes figurant dans la présente Politique.
          </p>
          <p>
            L’accès non autorisé à des Données Personnelles ou leur mauvaise
            utilisation peut constituer une infraction aux termes de la
            législation locale.
          </p>
        </template>
      </CardWithLineGeneric>

      <CardWithLineGeneric>
        <template v-slot:title>
          <vue-feather type="check" class="tw-text-orange-500 me-3" />
          Contact.
        </template>
        <template v-slot:body>
          <p>
            Pour toute question concernant la présente Politique, pour ne plus
            recevoir d’informations de la part de {{ APP_NAME }} ou pour toute
            demande d'exercice de vos droits concernant Vos Données
            Personnelles, Vous pouvez envoyer un courrier électronique à
            l’adresse électronique suivante : {{ APP_EMAIL }}
          </p>
        </template>
      </CardWithLineGeneric>

      <CardWithLineGeneric>
        <template v-slot:title>
          <vue-feather type="check" class="tw-text-orange-500 me-3" />
          Date d’entrée en vigueur/Révisions de la politique de protection des
          données personnelles
        </template>
        <template v-slot:body>
          <p>
            La présente Politique peut être mise à jour en fonction des besoins
            de {{ APP_NAME }} et des circonstances ou si la loi l’exige. Nous
            vous invitons par conséquent à prendre régulièrement connaissance
            des mises à jour.
          </p>
        </template>
      </CardWithLineGeneric>
    </div>
  </div>
</template>

<script>
import { APP_NAME, APP_EMAIL } from "../../statics/app_static";
import CardWithLineGeneric from "../generics/Cards/CardWithLine/CardWithLineGeneric.vue";

import SimpleSectionHeader from "../generics/Headers/SimpleSectionHeader/SimpleSectionHeader.vue";
export default {
  name: "PolitiquesConfidentialite",
  components: {
    SimpleSectionHeader,
    CardWithLineGeneric,
  },
  data() {
    return {
      APP_NAME,
      APP_EMAIL,
    };
  },
};
</script>

<style>
.section-politiques ul {
  list-style: circle;
  margin: inherit;
  padding: 10px 20px;
}
.section-politiques p {
  padding-top: 10px;
}
</style>
