<template>
    <!-- Start IoT Why Choose Us Area -->
    <section class="iot-why-choose-us ptb-80 pb-0">
        <div class="container">
            <div class="section-title">
                <h2>Our Core Approach</h2>
                <div class="bar"></div>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
            </div>
            
            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-iot-box">
                        <div class="icon">
                            <img src="../../../assets/img/icon1.png" alt="image">
                        </div>
                        <h3>Digital Technology</h3>
                        <p>Lorem ipsum dolor sit amet elit, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>
                        <a href="#"><vue-feather type="arrow-right"></vue-feather></a>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-iot-box">
                        <div class="icon">
                            <img src="../../../assets/img/icon2.png" alt="image">
                        </div>
                        <h3>Business Protection</h3>
                        <p>Lorem ipsum dolor sit amet elit, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>
                        <a href="#"><vue-feather type="arrow-right"></vue-feather></a>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                    <div class="single-iot-box">
                        <div class="icon">
                            <img src="../../../assets/img/icon3.png" alt="image">
                        </div>
                        <h3>Data Analysis</h3>
                        <p>Lorem ipsum dolor sit amet elit, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>
                        <a href="#"><vue-feather type="arrow-right"></vue-feather></a>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- End IoT Why Choose Us Area -->
</template>

<script>
export default {
    name: 'WhyChooseUs'
}
</script>