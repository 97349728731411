<template>
  <div>
    <SimpleSectionHeader
      :title="jobHeaderData.title?.label"
      :location="jobHeaderData.location"
    />
    <!-- :image="jobHeaderData.image" -->

    <span v-if="isLoading" class="tw-animate-pulse p-5">
      Chargement en cours ...
    </span>
    <span v-else-if="isOnError" class="tw-animate-pulse p-5 text-danger">
      Erreur de chargement ...
    </span>
    <div v-else>
      <!-- Job description -->
      <DetailsOffreSection :title="jobHeaderData.title?.label">
        <p>
          {{ jobDescription }}
        </p>
      </DetailsOffreSection>

      <!-- Compétences requises -->
      <DetailsOffreSection title="Compétences requises" v-if="jobSkills.length">
        <div class="features-details-desc">
          <ul class="features-details-list">
            <li v-for="(jobSkill, ind) in jobSkills" :key="'job_skill' + ind">
              {{ jobSkill.skill_name }}
            </li>
          </ul>
        </div>
      </DetailsOffreSection>

      <!-- Votre profil -->
      <DetailsOffreSection title="Votre profil" v-if="yourProfile.length">
        <div class="features-details-desc">
          <ul class="features-details-list">
            <li
              v-for="(yourProfileItem, ind) in yourProfile"
              :key="'profile' + ind"
            >
              {{ yourProfileItem.profile_name }}
            </li>
          </ul>
        </div>
      </DetailsOffreSection>

      <!-- Rénumération -->
      <DetailsOffreSection title="Rénumération">
        <p>{{ renumeration }}</p>
      </DetailsOffreSection>

      <!-- Votre profil -->
      <DetailsOffreSection title="Tags" class="services-content">
        <div class="d-flex flex-wrap gap-3">
          <div
            class="box text-uppercase d-flex align-items-center"
            v-for="(item, ind) in tags"
            :key="ind"
          >
            <vue-feather type="chevron-right"></vue-feather>
            <div class="mt-1">
              {{ item }}
            </div>
          </div>
        </div>
      </DetailsOffreSection>

      <OffrePostulerSection />
    </div>
  </div>
</template>

<script>
import { BACK_URL } from "../../statics/app_static";
import SimpleSectionHeader from "../generics/Headers/SimpleSectionHeader/SimpleSectionHeader.vue";
import DetailsOffreSection from "./details-offre/DetailsOffreSection.vue";
import OffrePostulerSection from "./details-offre/OffrePostulerSection.vue";
export default {
  // ? The param from route is in $route.params.id
  name: "DetailsOffre",
  components: {
    SimpleSectionHeader,
    DetailsOffreSection,
    OffrePostulerSection,
  },
  data() {
    return {
      isOnError: false,
      isLoading: true,
      jobHeaderData: {
        title: {},
        location: {
          date: "",
          location: "",
          writer: "",
        },
      },
      jobSkills: [],
      yourProfile: [],
      jobDescription: "",
      renumeration: "",
      tags: [],
    };
  },
  mounted() {
    this.getJobDetails();
  },
  methods: {
    getJobDetails() {
      fetch(BACK_URL + "/api/jobs/" + this.$route.params.id)
        .then((res) => res.json())
        .then((data) => {
          this.isLoading = false;
          this.jobHeaderData = {
            title: data.title,
            location: {
              date: data.date,
              writer: "Direction",
            },
          };
          this.renumeration = data.renumeration;
          this.jobSkills = data.job_skill;
          this.yourProfile = data.job_profiles;
          this.tags = data.tags.split(";");
          this.jobDescription = data.description;
        })
        .catch((e) => {
          this.isOnError = true;
          this.isLoading = false;
          console.error(e);
        });
    },
  },
};
</script>
