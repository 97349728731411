import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Toaster from "@meforma/vue-toaster";
import VueFeather from "vue-feather";

//import './input.css'
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

import "./assets/custom.scss";

const app = createApp(App).use(router).use(store).use(Toaster);
app.use(VueSweetalert2);
app.component(VueFeather.name, VueFeather).mount("#app");
