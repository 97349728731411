export const locations = [
  { name: "Afghanistan", code: "AF" },
  { name: "AfriqueduSud", code: "ZA" },
  { name: "Åland,Îles", code: "AX" },
  { name: "Albanie", code: "AL" },
  { name: "Algérie", code: "DZ" },
  { name: "Allemagne", code: "DE" },
  { name: "Andorre", code: "AD" },
  { name: "Angola", code: "AO" },
  { name: "Anguilla", code: "AI" },
  { name: "Antarctique", code: "AQ" },
  { name: "Antigua et Barbuda", code: "AG" },
  { name: "ArabieSaoudite", code: "SA" },
  { name: "Argentine", code: "AR" },
  { name: "Arménie", code: "AM" },
  { name: "Aruba", code: "AW" },
  { name: "Australie", code: "AU" },
  { name: "Autriche", code: "AT" },
  { name: "Azerbaïdjan", code: "AZ" },
  { name: "Bahamas", code: "BS" },
  { name: "Bahrein", code: "BH" },
  { name: "Bangladesh", code: "BD" },
  { name: "Barbade", code: "BB" },
  { name: "Bélarus", code: "BY" },
  { name: "Belgique", code: "BE" },
  { name: "Bélize", code: "BZ" },
  { name: "Bénin", code: "BJ" },
  { name: "Bermudes", code: "BM" },
  { name: "Bhoutan", code: "BT" },
  { name: "Bolivie(état pluri national de)", code: "BO" },
  { name: "Bonaire,Saint-Eustache et Saba", code: "BQ" },
  { name: "Bosnie-Herzégovine", code: "BA" },
  { name: "Botswana", code: "BW" },
  { name: "Bouvet,Ile", code: "BV" },
  { name: "Brésil", code: "BR" },
  { name: "Brunéi Darussalam", code: "BN" },
  { name: "Bulgarie", code: "BG" },
  { name: "BurkinaFaso", code: "BF" },
  { name: "Burundi", code: "BI" },
  { name: "CaboVerde", code: "CV" },
  { name: "Caïmans,Iles", code: "KY" },
  { name: "Cambodge", code: "KH" },
  { name: "Cameroun", code: "CM" },
  { name: "Canada", code: "CA" },
  { name: "Chili", code: "CL" },
  { name: "Chine", code: "CN" },
  { name: "Christmas,île", code: "CX" },
  { name: "Chypre", code: "CY" },
  { name: "Cocos/Keeling(Îles)", code: "CC" },
  { name: "Colombie", code: "CO" },
  { name: "Comores", code: "KM" },
  { name: "Congo", code: "CG" },
  { name: "Congo,République démocratique du", code: "CD" },
  { name: "Cook,Iles", code: "CK" },
  { name: "Corée,Républiquede", code: "KR" },
  { name: "Corée,République populaire démocratique de", code: "KP" },
  { name: "CostaRica", code: "CR" },
  { name: "CÃ´ted'Ivoire", code: "CI" },
  { name: "Croatie", code: "HR" },
  { name: "Cuba", code: "CU" },
  { name: "Curaçao", code: "CW" },
  { name: "Danemark", code: "DK" },
  { name: "Djibouti", code: "DJ" },
  { name: "Dominicaine,République", code: "DO" },
  { name: "Dominique", code: "DM" },
  { name: "Egypte", code: "EG" },
  { name: "ElSalvador", code: "SV" },
  { name: "Emirat sarabesunis", code: "AE" },
  { name: "Equateur", code: "EC" },
  { name: "Erythrée", code: "ER" },
  { name: "Espagne", code: "ES" },
  { name: "Estonie", code: "EE" },
  { name: "Etats-Unisd'Amérique", code: "US" },
  { name: "Ethiopie", code: "ET" },
  { name: "Falkland/Malouines(Îles)", code: "FK" },
  { name: "Féroé,îles", code: "FO" },
  { name: "Fidji", code: "FJ" },
  { name: "Finlande", code: "FI" },
  { name: "France", code: "FR" },
  { name: "Gabon", code: "GA" },
  { name: "Gambie", code: "GM" },
  { name: "Géorgie", code: "GE" },
  { name: "Géorgie du sud et les îles Sandwich du sud", code: "GS" },
  { name: "Ghana", code: "GH" },
  { name: "Gibraltar", code: "GI" },
  { name: "Grèce", code: "GR" },
  { name: "Grenade", code: "GD" },
  { name: "Groenland", code: "GL" },
  { name: "Guadeloupe", code: "GP" },
  { name: "Guam", code: "GU" },
  { name: "Guatemala", code: "GT" },
  { name: "Guernesey", code: "GG" },
  { name: "Guinée", code: "GN" },
  { name: "Guinée-Bissau", code: "GW" },
  { name: "Guinée équatoriale", code: "GQ" },
  { name: "Guyana", code: "GY" },
  { name: "Guyane française", code: "GF" },
  { name: "Haïti", code: "HT" },
  { name: "Heard,Ile et MacDonald,îles", code: "HM" },
  { name: "Honduras", code: "HN" },
  { name: "HongKong", code: "HK" },
  { name: "Hongrie", code: "HU" },
  { name: "ÎledeMan", code: "IM" },
  { name: "Îles mineures éloignées des Etats-Unis", code: "UM" },
  { name: "Îles vierges britanniques", code: "VG" },
  { name: "Îles vierges des Etats-Unis", code: "VI" },
  { name: "Inde", code: "IN" },
  { name: "Indien(Territoire britannique de l'océan)", code: "IO" },
  { name: "Indonésie", code: "ID" },
  { name: "Iran,République islamique d'", code: "IR" },
  { name: "Iraq", code: "IQ" },
  { name: "Irlande", code: "IE" },
  { name: "Islande", code: "IS" },
  { name: "Italie", code: "IT" },
  { name: "Jamaïque", code: "JM" },
  { name: "Japon", code: "JP" },
  { name: "Jersey", code: "JE" },
  { name: "Jordanie", code: "JO" },
  { name: "Kazakhstan", code: "KZ" },
  { name: "Kenya", code: "KE" },
  { name: "Kirghizistan", code: "KG" },
  { name: "Kiribati", code: "KI" },
  { name: "Koweït", code: "KW" },
  { name: "Lao,République démocratique populaire", code: "LA" },
  { name: "Lesotho", code: "LS" },
  { name: "Lettonie", code: "LV" },
  { name: "Liban", code: "LB" },
  { name: "Libéria", code: "LR" },
  { name: "Libye", code: "LY" },
  { name: "Liechtenstein", code: "LI" },
  { name: "Lituanie", code: "LT" },
  { name: "Luxembourg", code: "LU" },
  { name: "Macao", code: "MO" },
  { name: "Macédoine,l'ex-République yougoslavede", code: "MK" },
  { name: "Madagascar", code: "MG" },
  { name: "Malaisie", code: "MY" },
  { name: "Malawi", code: "MW" },
  { name: "Maldives", code: "MV" },
  { name: "Mali", code: "ML" },
  { name: "Malte", code: "MT" },
  { name: "Mariannes du nord,Iles", code: "MP" },
  { name: "Maroc", code: "MA" },
  { name: "Marshall,Iles", code: "MH" },
  { name: "Martinique", code: "MQ" },
  { name: "Maurice", code: "MU" },
  { name: "Mauritanie", code: "MR" },
  { name: "Mayotte", code: "YT" },
  { name: "Mexique", code: "MX" },
  { name: "Micronésie,EtatsFédérésde", code: "FM" },
  { name: "Moldova,Républiquede", code: "MD" },
  { name: "Monaco", code: "MC" },
  { name: "Mongolie", code: "MN" },
  { name: "Monténégro", code: "ME" },
  { name: "Montserrat", code: "MS" },
  { name: "Mozambique", code: "MZ" },
  { name: "Myanmar", code: "MM" },
  { name: "Namibie", code: "NA" },
  { name: "Nauru", code: "NR" },
  { name: "Népal", code: "NP" },
  { name: "Nicaragua", code: "NI" },
  { name: "Niger", code: "NE" },
  { name: "Nigéria", code: "NG" },
  { name: "Niue", code: "NU" },
  { name: "Norfolk,Ile", code: "NF" },
  { name: "Norvège", code: "NO" },
  { name: "Nouvelle-Calédonie", code: "NC" },
  { name: "Nouvelle-Zélande", code: "NZ" },
  { name: "Oman", code: "OM" },
  { name: "Ouganda", code: "UG" },
  { name: "Ouzbékistan", code: "UZ" },
  { name: "Pakistan", code: "PK" },
  { name: "Palaos", code: "PW" },
  { name: "Palestine,Etatde", code: "PS" },
  { name: "Panama", code: "PA" },
  { name: "Papouasie-Nouvelle-Guinée", code: "PG" },
  { name: "Paraguay", code: "PY" },
  { name: "Pays-Bas", code: "NL" },
  { name: "Paysinconnu", code: "XX" },
  { name: "Pays multiples", code: "ZZ" },
  { name: "Pérou", code: "PE" },
  { name: "Philippines", code: "PH" },
  { name: "Pitcairn", code: "PN" },
  { name: "Pologne", code: "PL" },
  { name: "Polynésie française", code: "PF" },
  { name: "PortoRico", code: "PR" },
  { name: "Portugal", code: "PT" },
  { name: "Qatar", code: "QA" },
  { name: "République arabe syrienne", code: "SY" },
  { name: "République centrafricaine", code: "CF" },
  { name: "Réunion", code: "RE" },
  { name: "Roumanie", code: "RO" },
  { name: "Royaume-UnideGrande-Bretagneetd'IrlandeduNord", code: "GB" },
  { name: "Russie,Fédérationde", code: "RU" },
  { name: "Rwanda", code: "RW" },
  { name: "Sahara occidental", code: "EH" },
  { name: "Saint-Barthélemy", code: "BL" },
  { name: "Saint-Kitts-et-Nevis", code: "KN" },
  { name: "Saint-Marin", code: "SM" },
  { name: "Saint-Martin(partiefrançaise)", code: "MF" },
  { name: "Saint-Martin(partienéerlandaise)", code: "SX" },
  { name: "Saint-Pierre-et-Miquelon", code: "PM" },
  { name: "Saint-Siège", code: "VA" },
  { name: "Saint-Vincent-et-les-Grenadines", code: "VC" },
  { name: "Sainte-Hélène,Ascension et Tristanda Cunha", code: "SH" },
  { name: "Sainte-Lucie", code: "LC" },
  { name: "Salomon,Iles", code: "SB" },
  { name: "Samoa", code: "WS" },
  { name: "Samoa américaines", code: "AS" },
  { name: "SaoTomé-et-Principe", code: "ST" },
  { name: "Sénégal", code: "SN" },
  { name: "Serbie", code: "RS" },
  { name: "Seychelles", code: "SC" },
  { name: "SierraLeone", code: "SL" },
  { name: "Singapour", code: "SG" },
  { name: "Slovaquie", code: "SK" },
  { name: "Slovénie", code: "SI" },
  { name: "Somalie", code: "SO" },
  { name: "Soudan", code: "SD" },
  { name: "Soudan du Sud", code: "SS" },
  { name: "SriLanka", code: "LK" },
  { name: "Suède", code: "SE" },
  { name: "Suisse", code: "CH" },
  { name: "Suriname", code: "SR" },
  { name: "Svalbard et île Jan Mayen", code: "SJ" },
  { name: "Swaziland", code: "SZ" },
  { name: "Tadjikistan", code: "TJ" },
  { name: "Taïwan,Province de Chine", code: "TW" },
  { name: "Tanzanie,République unie de", code: "TZ" },
  { name: "Tchad", code: "TD" },
  { name: "Tchèque,République", code: "CZ" },
  { name: "Terres australes françaises", code: "TF" },
  { name: "Thaïlande", code: "TH" },
  { name: "Timor-Leste", code: "TL" },
  { name: "Togo", code: "TG" },
  { name: "Tokelau", code: "TK" },
  { name: "Tonga", code: "TO" },
  { name: "Trinité-et-Tobago", code: "TT" },
  { name: "Tunisie", code: "TN" },
  { name: "Turkménistan", code: "TM" },
  { name: "Turks-et-Caïcos(Îles)", code: "TC" },
  { name: "Turquie", code: "TR" },
  { name: "Tuvalu", code: "TV" },
  { name: "Ukraine", code: "UA" },
  { name: "Uruguay", code: "UY" },
  { name: "Vanuatu", code: "VU" },
  { name: "Venezuela(République bolivarienne du)", code: "VE" },
  { name: "VietNam", code: "VN" },
  { name: "Wallis et Futuna", code: "WF" },
  { name: "Yémen", code: "YE" },
  { name: "Zambie", code: "ZM" },
  { name: "Zimbabwe", code: "ZW" },
];
