<template>
  <div>
    <SideTimeLineItem
      v-for="(item, ind) in items"
      :label="item.title"
      :key="ind"
      :direction="ind % 2 === 0 ? 'left' : 'right'"
      :isFinalLine="ind === items.length - 1"
      :icon="item.icon"
    />
  </div>
</template>

<script>
import SideTimeLineItem from "./SideTimeLineItem.vue";
import userIcon from "@/assets/img/join-us/user-icon.svg";
import usersIcon from "@/assets/img/join-us/users-icon.svg";
import interviewIcon from "@/assets/img/join-us/interview-icon.svg";
import meetingIcon from "@/assets/img/join-us/meeting-icon.svg";
import chatIcon from "@/assets/img/join-us/chat-icon.svg";

export default {
  components: { SideTimeLineItem },
  name: "SideTimeLine",
  data() {
    return {
      items: [
        { title: "Sourcing recrutement", icon: userIcon },
        { title: "Entretien RH", icon: usersIcon },
        { title: "évaluation technique/ Fonctionnelle", icon: interviewIcon },
        { title: "Intégration dans l'entreprise", icon: meetingIcon },
        {
          title: "Suivi de carrière et formation continue",
          icon: chatIcon,
        },
      ],
    };
  },
};
</script>
