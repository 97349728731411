<template>
    <div class="team-area repair-team-area ptb-80 bg-f9f6f6">
        <div class="container">
            <div class="section-title">
                <h2>Our Awesome Team</h2>
                <div class="bar"></div>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
            </div>

            <div class="row">
                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="single-team">
                        <div class="team-image">
                            <img src="../../../assets/img/repair-team-image/1.jpg" alt="image">
                        </div>

                        <div class="team-content">
                            <div class="team-info">
                                <h3>Josh Buttler</h3>
                                <span>CEO & Founder</span>
                            </div>

                            <ul>
                                <li><a href="#"><vue-feather type="facebook"></vue-feather></a></li>
                                <li><a href="#"><vue-feather type="twitter"></vue-feather></a></li>
                                <li><a href="#"><vue-feather type="linkedin"></vue-feather></a></li>
                                <li><a href="#"><vue-feather type="gitlab"></vue-feather></a></li>
                            </ul>

                            <p>Risus commodo viverra maecenas accumsan lacus vel facilisis quis ipsum. </p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="single-team">
                        <div class="team-image">
                            <img src="../../../assets/img/repair-team-image/2.jpg" alt="image">
                        </div>

                        <div class="team-content">
                            <div class="team-info">
                                <h3>Alex Maxwel</h3>
                                <span>Marketing Manager</span>
                            </div>

                            <ul>
                                <li><a href="#"><vue-feather type="facebook"></vue-feather></a></li>
                                <li><a href="#"><vue-feather type="twitter"></vue-feather></a></li>
                                <li><a href="#"><vue-feather type="linkedin"></vue-feather></a></li>
                                <li><a href="#"><vue-feather type="gitlab"></vue-feather></a></li>
                            </ul>

                            <p>Risus commodo viverra maecenas accumsan lacus vel facilisis quis ipsum. </p>
                        </div>
                    </div>
                </div>
                
                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="single-team">
                        <div class="team-image">
                            <img src="../../../assets/img/repair-team-image/3.jpg" alt="image">
                        </div>

                        <div class="team-content">
                            <div class="team-info">
                                <h3>Janny Cotller</h3>
                                <span>Web Developer</span>
                            </div>

                            <ul>
                                <li><a href="#"><vue-feather type="facebook"></vue-feather></a></li>
                                <li><a href="#"><vue-feather type="twitter"></vue-feather></a></li>
                                <li><a href="#"><vue-feather type="linkedin"></vue-feather></a></li>
                                <li><a href="#"><vue-feather type="gitlab"></vue-feather></a></li>
                            </ul>

                            <p>Risus commodo viverra maecenas accumsan lacus vel facilisis quis ipsum. </p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="single-team">
                        <div class="team-image">
                            <img src="../../../assets/img/repair-team-image/4.jpg" alt="image">
                        </div>

                        <div class="team-content">
                            <div class="team-info">
                                <h3>Jason Statham</h3>
                                <span>UX/UI Designer</span>
                            </div>

                            <ul>
                                <li><a href="#"><vue-feather type="facebook"></vue-feather></a></li>
                                <li><a href="#"><vue-feather type="twitter"></vue-feather></a></li>
                                <li><a href="#"><vue-feather type="linkedin"></vue-feather></a></li>
                                <li><a href="#"><vue-feather type="gitlab"></vue-feather></a></li>
                            </ul>

                            <p>Risus commodo viverra maecenas accumsan lacus vel facilisis quis ipsum. </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'OurTeam'
}
</script>