<template>
  <div class="feedback-area ptb-80">
    <div class="container">
      <div class="section-title">
        <h2>
          <well-formatted-title
            blackArea="Nos Dernières Offres"
            :lineBreak="false"
          />
        </h2>
      </div>
      {{ jobs }}

      <div class="feedback-slides tw-max-w-full">
        <Carousel
          :autoplay="5000"
          :settings="settings"
          :breakpoints="breakpoints"
          v-model="slideNumber"
          :wrap-around="true"
        >
          <slide v-for="slide in carouselItems" :key="slide.id">
            <router-link :to="'/details-offre/' + slide.id">
              <div class="item px-2">
                <div
                  class="single-hosting-features tw-px-5 tw-py-10 tw-rounded-none"
                >
                  <h2 class="text-start my-2 tw-text-2xl">
                    {{ slide.title }}
                  </h2>
                  <p>
                    Type de poste : <strong>{{ slide.description }}</strong>
                  </p>
                </div>
              </div>
            </router-link>
          </slide>

          <template #addons>
            <navigation>
              <template #next>
                <vue-feather type="arrow-right"></vue-feather>
              </template>
              <template #prev>
                <vue-feather type="arrow-left"></vue-feather>
              </template>
            </navigation>
          </template>
        </Carousel>
      </div>
    </div>

    <div class="shape1">
      <img src="../../../assets/img/shape1.png" alt="shape" />
    </div>
    <div class="shape2 rotateme">
      <img src="../../../assets/img/shape2.svg" alt="shape" />
    </div>
    <div class="shape4">
      <img src="../../../assets/img/shape4.svg" alt="shape" />
    </div>
    <div class="shape5">
      <img src="../../../assets/img/shape5.png" alt="shape" />
    </div>
    <div class="shape6 rotateme">
      <img src="../../../assets/img/shape4.svg" alt="shape" />
    </div>
    <div class="shape7">
      <img src="../../../assets/img/shape4.svg" alt="shape" />
    </div>
    <div class="shape8 rotateme">
      <img src="../../../assets/img/shape2.svg" alt="shape" />
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { Carousel, Slide, Navigation } from "vue3-carousel";
import WellFormattedTitle from "../../generics/Typography/Divider/WellFormattedTitle.vue";

import "vue3-carousel/dist/carousel.css";
import { BACK_URL } from "../../../statics/app_static";

const settings = ref({
  itemsToShow: 1,
  snapAlign: "center",
});

const breakpoints = ref({
  700: {
    itemsToShow: 2.5,
    snapAlign: "center",
  },
  // 1024 and up
  1024: {
    itemsToShow: 3.5,
    snapAlign: "start",
  },
});
const slideNumber = ref(0);
const carouselItems = ref([]);

const getJobs = (page = 0) => {
  fetch(BACK_URL + "/api/jobs?page=" + page)
    .then((res) => res.json())
    .then(({ data }) => {
      carouselItems.value = data
        .map((el) => {
          return {
            id: el.id,
            title: el.title?.label,
            description: "CDI",
          };
        })
        .splice(0, 5);
    });
};

onMounted(() => {
  getJobs();
});
</script>

<style>
.feedback-slides .carousel__slide--active ~ .carousel__slide {
  transform: rotateY(20deg) scale(0.9);
}
.feedback-slides .carousel__slide--sliding {
  transition: 0.5s;
}
.feedback-slides .carousel .carousel__next.carousel__next {
  right: -70px !important;
}
.feedback-slides .carousel .carousel__prev {
  left: -70px !important;
}
</style>
