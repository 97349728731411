<template>
    <div>
        <!-- Start Page Title -->
        <div class="page-title-area">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <h2>Services</h2>
                    </div>
                </div>
			</div>
			
			<div class="shape1"><img src="../../../assets/img/shape1.png" alt="shape"></div>
			<div class="shape2 rotateme"><img src="../../../assets/img/shape2.svg" alt="shape"></div>
			<div class="shape3"><img src="../../../assets/img/shape3.svg" alt="shape"></div>
			<div class="shape4"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
			<div class="shape5"><img src="../../../assets/img/shape5.png" alt="shape"></div>
			<div class="shape6 rotateme"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
			<div class="shape7"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
			<div class="shape8 rotateme"><img src="../../../assets/img/shape2.svg" alt="shape"></div>
        </div>
        <!-- End Page Title -->

        <!-- Start Services Area -->
		<section class="services-area-two ptb-80 bg-f9f6f6">
			<div class="container">
				<div class="section-title">
					<h2>Our Services</h2>
					<div class="bar"></div>
					<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
				</div>

				<div class="row">
					<div class="col-lg-4 col-md-6 col-sm-6">
						<div class="single-services-box">
							<div class="icon">
								<vue-feather type="settings"></vue-feather>
							</div>

							<h3><a href="#">Incredible Infrastructure</a></h3>
							<p>Lorem ipsum dolor amet, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>
						</div>
					</div>

					<div class="col-lg-4 col-md-6 col-sm-6">
						<div class="single-services-box">
							<div class="icon">
								<vue-feather type="mail"></vue-feather>
							</div>

							<h3><a href="#">Email Notifications</a></h3>
							<p>Lorem ipsum dolor amet, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>
						</div>
					</div>

					<div class="col-lg-4 col-md-6 col-sm-6">
						<div class="single-services-box">
							<div class="icon">
								<vue-feather type="bell"></vue-feather>
							</div>

							<h3><a href="#">Best Analytics Audits</a></h3>
							<p>Lorem ipsum dolor amet, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>
						</div>
					</div>

					<div class="col-lg-4 col-md-6 col-sm-6">
						<div class="single-services-box">
							<div class="icon bg-c679e3">
								<vue-feather type="grid"></vue-feather>
							</div>

							<h3><a href="#">Simple Dashboard</a></h3>
							<p>Lorem ipsum dolor amet, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>
						</div>
					</div>

					<div class="col-lg-4 col-md-6 col-sm-6">
						<div class="single-services-box">
							<div class="icon bg-c679e3">
								<vue-feather type="info"></vue-feather>
							</div>

							<h3><a href="#">Information Retrieval</a></h3>
							<p>Lorem ipsum dolor amet, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>
						</div>
					</div>

					<div class="col-lg-4 col-md-6 col-sm-6">
						<div class="single-services-box">
							<div class="icon bg-c679e3">
								<vue-feather type="hard-drive"></vue-feather>
							</div>

							<h3><a href="#">Deep Technical SEO</a></h3>
							<p>Lorem ipsum dolor amet, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>
						</div>
					</div>

					<div class="col-lg-4 col-md-6 col-sm-6">
						<div class="single-services-box">
							<div class="icon bg-eb6b3d">
								<vue-feather type="mouse-pointer"></vue-feather>
							</div>

							<h3><a href="#">Drag and Drop Functionality</a></h3>
							<p>Lorem ipsum dolor amet, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>
						</div>
					</div>

					<div class="col-lg-4 col-md-6 col-sm-6">
						<div class="single-services-box">
							<div class="icon bg-eb6b3d">
								<vue-feather type="bell"></vue-feather>
							</div>

							<h3><a href="#">Deadline Reminders</a></h3>
							<p>Lorem ipsum dolor amet, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>
						</div>
					</div>

					<div class="col-lg-4 col-md-6 col-sm-6">
						<div class="single-services-box">
							<div class="icon bg-eb6b3d">
								<vue-feather type="send"></vue-feather>
							</div>

							<h3><a href="#">Modern Keyword Analysis</a></h3>
							<p>Lorem ipsum dolor amet, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>
						</div>
					</div>
				</div>
			</div>
		</section>
		<!-- End Services Area -->
    </div>
</template>

<script>
export default {
    name: 'ServicesOne'
}
</script>