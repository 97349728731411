<template>
  <div class="bigdata-analytics-banner">
    <div class="container">
      <div class="bigdata-analytics-content">
        <h1>Leading Big Data Analytics Company</h1>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum
          suspendisse ultrices gravida.
        </p>
        <a href="#" class="btn btn-primary">Get Started</a>
      </div>
    </div>

    <div class="banner-boxes-area">
      <div class="container">
        <div class="row">
          <div class="col-lg-4 col-md-6 col-sm-6">
            <div class="single-banner-boxes">
              <div class="icon">
                <vue-feather type="server"></vue-feather>
              </div>
              <h3>Analyze Your Data</h3>
              <p>
                Lorem ipsum dolor sit amet elit, adipiscing, sed do eiusmod
                tempor incididunt ut labore dolore magna aliqua.
              </p>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 col-sm-6">
            <div class="single-banner-boxes">
              <div class="icon">
                <vue-feather type="code"></vue-feather>
              </div>
              <h3>Customized Plan</h3>
              <p>
                Lorem ipsum dolor sit amet elit, adipiscing, sed do eiusmod
                tempor incididunt ut labore dolore magna aliqua.
              </p>
            </div>
          </div>

          <div
            class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3"
          >
            <div class="single-banner-boxes">
              <div class="icon">
                <vue-feather type="users"></vue-feather>
              </div>
              <h3>Implement Solution</h3>
              <p>
                Lorem ipsum dolor sit amet elit, adipiscing, sed do eiusmod
                tempor incididunt ut labore dolore magna aliqua.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MainBanner",
};
</script>