<template>
  <div class="pt-5">
    <!-- Start Page Title -->
    <SimpleSectionHeader title="Contactez-nous" />
    <!-- End Page Title -->

    <!-- Start Contact Info Area -->
    <div class="contact-info-area ptb-80">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-4 col-md-6 col-sm-6">
            <div class="contact-info-box">
              <div class="icon">
                <vue-feather type="mail"></vue-feather>
              </div>
              <h3>Contactez-nous</h3>
              <p>
                <a :href="'mailto:' + APP_EMAIL">
                  {{ APP_EMAIL }}
                </a>
              </p>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 col-sm-6">
            <div class="contact-info-box">
              <div class="icon">
                <vue-feather type="map-pin"></vue-feather>
              </div>
              <h3>Adresse</h3>
              <p>
                {{ APP_ADDRESS }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Contact Info Area -->

    <!-- Start Contact Area -->
    <div class="contact-area ptb-80 pt-0">
      <div class="container">
        <div class="section-title">
          <h2>Prenez contact avec nous</h2>
          <div class="bar"></div>
          <p>Nous serons heureux de vous aider !</p>
        </div>

        <div class="row h-100 justify-content-center align-items-center">
          <div class="col-lg-6 col-md-12">
            <img src="../../../assets/img/1.png" alt="image" />
          </div>

          <div class="col-lg-6 col-md-12">
            <div
              class="tw-bg-green-100 tw-border tw-border-green-400 tw-text-green-700 tw-px-4 tw-py-3 tw-rounded-lg tw-shadow-md tw-flex tw-items-center tw-space-x-2"
              v-if="isMessageSent"
            >
              <svg
                class="tw-w-5 tw-h-5 tw-text-green-500"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M5 13l4 4L19 7"
                ></path>
              </svg>
              <span>
                Votre message a été envoyé, nous vous contacterons dans les plus
                brefs délais.
              </span>
            </div>
            <form id="contactForm" @submit="validateSubmit" v-else>
              <div class="row">
                <div class="col-lg-12 col-md-12">
                  <div class="form-group">
                    <input
                      type="text"
                      name="full_name"
                      class="form-control"
                      required
                      data-error="Please enter your name"
                      placeholder="Nom &amp; prénom *"
                    />
                    <div class="help-block with-errors"></div>
                  </div>
                </div>

                <div class="col-lg-12 col-md-12">
                  <div class="form-group">
                    <input
                      type="email"
                      class="form-control"
                      name="email"
                      required
                      data-error="Please enter your email"
                      placeholder="Email *"
                    />
                    <div class="help-block with-errors"></div>
                  </div>
                </div>

                <div class="col-lg-12">
                  <InputWithLabel
                    placeholder="Numéro de téléphone *"
                    name="phone"
                    type="phone"
                  />
                </div>

                <div class="col-lg-12 col-md-6">
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      required
                      name="objet"
                      data-error="Please enter your subject"
                      placeholder="Objet *"
                    />
                    <div class="help-block with-errors"></div>
                  </div>
                </div>

                <div class="col-lg-12 col-md-12">
                  <InputWithLabel
                    placeholder="Votre message *"
                    type="textarea"
                    name="message"
                  />
                </div>

                <div class="col-lg-12 col-md-12">
                  <div class="form-group mb-0">
                    <div class="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        class="custom-control-input me-2"
                        id="customCheck1"
                        required
                      />
                      <label class="custom-control-label" for="customCheck1">
                        J'accepte la
                        <router-link to="/politiques-confidentialite">
                          politique de confidentialité
                        </router-link>
                        {{ APP_NAME }}
                      </label>
                    </div>
                  </div>
                  <button type="submit" class="btn btn-primary">
                    Envoyer message
                  </button>
                  <div id="msgSubmit" class="h3 text-center hidden"></div>
                  <div class="clearfix"></div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- End Contact Area -->
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import {
  APP_ADDRESS,
  APP_EMAIL,
  APP_NAME,
  BACK_URL,
} from "../../../statics/app_static";
import SimpleSectionHeader from "../../generics/Headers/SimpleSectionHeader/SimpleSectionHeader.vue";
import InputWithLabel from "../../generics/Inputs/InputWithLabel/InputWithLabel.vue";

const isMessageSent = ref(false);

const validateSubmit = (e) => {
  e.preventDefault();
  const form = document.getElementById("contactForm");
  const formData = new FormData(form);

  fetch(BACK_URL + "/api/contact/add", {
    method: "POST",
    body: formData,
  })
    .then((res) => res.text())
    .then(() => {
      isMessageSent.value = true;
      sessionStorage.setItem("is_message_sent", "1");
    })
    .catch((e) => {
      console.error({ e });
    });
};

const hasAlreadyMessage = () => {
  return sessionStorage.getItem("is_message_sent") === "1";
};

onMounted(() => {
  if (hasAlreadyMessage()) {
    isMessageSent.value = true;
  }
});
</script>
