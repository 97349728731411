<template>
  <div :class="customClass ? customClass : 'page-title-area'">
    <div class="d-table">
      <div :class="isSectionTitle ? 'section-title' : 'd-table-cell'">
        <div class="container">
          <img
            v-if="image"
            class="img-fluid mb-3 rounded-3 tw-mx-auto"
            width="200"
            height="100"
            :src="image"
            :alt="title"
          />
          <h2 v-if="title">
            <well-formatted-title v-if="wellFormatted" :blackArea="title" />
            <span v-else>
              {{ title }}
            </span>
          </h2>

          <SimpleSectionHeaderLocs
            v-if="location"
            :date="location.date"
            :writer="location.writer"
            :location="location.location"
          />
        </div>
      </div>
      <slot></slot>
    </div>
    <div class="shape1">
      <img src="../../../../assets/img/shape1.png" alt="shape" />
    </div>
    <div class="shape2 rotateme">
      <img src="../../../../assets/img/shape2.svg" alt="shape" />
    </div>
    <div class="shape3">
      <img src="../../../../assets/img/shape3.svg" alt="shape" />
    </div>
    <div class="shape4">
      <img src="../../../../assets/img/shape4.svg" alt="shape" />
    </div>
    <div class="shape5">
      <img src="../../../../assets/img/shape5.png" alt="shape" />
    </div>
    <div class="shape6 rotateme">
      <img src="../../../../assets/img/shape4.svg" alt="shape" />
    </div>
    <div class="shape7">
      <img src="../../../../assets/img/shape4.svg" alt="shape" />
    </div>
    <div class="shape8 rotateme">
      <img src="../../../../assets/img/shape2.svg" alt="shape" />
    </div>
  </div>
</template>

<script>
import WellFormattedTitle from "../../Typography/Divider/WellFormattedTitle.vue";
import SimpleSectionHeaderLocs from "./SimpleSectionHeaderLocs.vue";
export default {
  name: "SimpleSectionHeader",
  components: {
    SimpleSectionHeaderLocs,
    WellFormattedTitle,
  },
  props: {
    title: String,
    image: {
      type: String,
      default: "",
    },
    wellFormatted: {
      type: Boolean,
      default: false,
    },
    location: Object,
    customClass: {
      type: String,
      default: "",
    },
    isSectionTitle: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
