<template>
  <div class="lg:tw-px-[140px] tw-px-5 tw-my-20">
    <div class="row align-items-center">
      <div class="col-lg-6 section-avenir">
        <p class="tw-text-[35px] tw-font-extrabold tw-leading-[37px]">
          <span class="tw-text-slate-950"> WORLDATA TEAM </span>
          <br />
          <span class="tw-text-wdata-orange">
            Facilitateur dans l’atteinte de vos objectifs
          </span>
        </p>
        <div
          class="tw-my-3 tw-h-0.5 tw-bg-[#19212e] tw-border-opacity-50 tw-w-32"
        ></div>
        <span class="tw-text-slate-700">
          <div class="tw-py-6">
            ESN spécialisée dans les métiers de l'infrastructure, du DevOps et
            du Cloud. <br />
            Nous couvrons l'ensemble du cycle de vie d'un projet, de la
            conception à la maintenance continue en mettant l'accent sur une
            priorité : simplifier l'atteinte des objectifs métiers de nos
            clients. <br />
            WORLDATA TEAM est là pour vous accompagner que vous envisagiez une
            migration vers le Cloud, une automatisation des processus grâce aux
            pratiques DevOps ou un renforcement de la sécurité de vos
            infrastructures tout en garantissant le bon fonctionnement de votre
            Legacy.
          </div>
          <div class="tw-py-2">
            Un partenariat solide et durable basé sur la confiance mutuelle et
            une collaboration étroite ne peut se construire sans partager de
            vraies valeurs.
          </div>
          <ul class="tw-pl-5 tw-py-5">
            <li>
              <strong>ENGAGEMENT</strong><br />
              La fidélité de nos clients et partenaires est la meilleure preuve
              de notre capacité à honorer nos engagements.
            </li>
            <li>
              <strong>ÉCOUTE</strong> <br />
              Nous croyons fermement que l'écoute active est essentielle pour
              comprendre pleinement les besoins et les défis de nos clients mais
              également de nos collaborateurs. Nous sommes toujours disponibles
              pour écouter attentivement les retours de nos clients afin de nous
              assurer que nous répondons parfaitement à leurs attentes.
            </li>
            <li>
              <strong>TRANSPARENCE</strong> <br />
              Nous pratiquons le parler vrai, que ce soit dans nos relations
              clients, dans nos recrutements ou dans la gestion de nos
              collaborateurs. <br />

              La transparence est un pilier fondamental de notre relation avec
              tous les acteurs d’un projet. Nous croyons en une communication
              ouverte, honnête et transparente à tous les niveaux de notre
              entreprise.
            </li>
          </ul>
        </span>
      </div>
      <div class="col-lg-3 tw-py-[140px] stats--graphs">
        <h4 class="tw-text-[27px] tw-font-extrabold tw-leading-[30px] tw-mb-5">
          Résultat <br />{{ PREV_YEAR }}
        </h4>
        <div
          class="tw-my-3 tw-h-0.5 tw-bg-[#19212e] tw-border-opacity-50 tw-w-32"
        ></div>

        <div class="tw-flex tw-flex-col">
          <div class="stats--graph tw-stroke-wdata-orange">
            <svg viewBox="0 0 36 36" class="graph">
              <path
                d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
                fill="none"
                stroke-width="1"
                stroke-dasharray="90, 100"
                data-percentage="90"
              ></path>
            </svg>

            <div
              class="graph--content d-flex flex-lg-column justify-content-center align-items-center"
            >
              <span class="graph--number number">120</span>
              <span class="tw-text-wdata-orange">Effectif</span>
            </div>
          </div>
          <div class="stats--graph tw-stroke-wdata-orange">
            <svg
              viewBox="0 0 36 36"
              class="graph d-flex flex-lg-column justify-content-center align-items-center"
            >
              <path
                d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
                fill="none"
                stroke-width="1"
                stroke-dasharray="91, 100"
                data-percentage="91"
              ></path>
            </svg>

            <div
              class="graph--content d-flex flex-lg-column justify-content-center align-items-center"
            >
              <span class="graph--number number">12,7</span>
              <span class="tw-text-wdata-orange">M€ de CA</span>
            </div>
          </div>
        </div>
      </div>

      <div
        class="col-lg-3 tw-py-[140px] stats--graphs tw-bg-wdata-blue tw-rounded-none"
      >
        <div class="tw-flex tw-flex-col align-items-center">
          <h4
            class="tw-text-[27px] tw-font-extrabold tw-leading-[30px] tw-mb-5 text-white"
          >
            Horizon <br />{{ CURR_YEAR }}
          </h4>
          <div
            class="tw-my-3 tw-h-0.5 tw-bg-sky-200 tw-border-opacity-50 tw-w-32"
          ></div>
          <div class="stats--graph tw-stroke-wdata-orange">
            <svg viewBox="0 0 36 36" class="graph">
              <path
                d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
                fill="none"
                stroke-width="1"
                stroke-dasharray="100, 100"
                data-percentage="100"
              ></path>
            </svg>

            <div
              class="graph--content d-flex flex-lg-column justify-content-center align-items-center"
            >
              <span class="graph--number number text-white"> 135</span>
              <span class="tw-text-sky-200">Effectif</span>
            </div>
          </div>
          <div class="stats--graph tw-stroke-wdata-orange">
            <svg
              viewBox="0 0 36 36"
              class="graph d-flex flex-lg-column justify-content-center align-items-center"
            >
              <path
                d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
                fill="none"
                stroke-width="1"
                stroke-dasharray="100, 100"
                data-percentage="100"
              ></path>
            </svg>

            <div
              class="graph--content d-flex flex-lg-column justify-content-center align-items-center"
            >
              <span class="graph--number number text-white"> 14,2</span>
              <span class="graph--text tw-text-sky-200">M€ de CA</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { APP_NAME } from "../../../statics/app_static";
export default {
  name: "AvenirArea",
  data() {
    return {
      APP_NAME,
      PREV_YEAR: new Date().getFullYear() - 1,
      CURR_YEAR: new Date().getFullYear(),
    };
  },
};
</script>
<style>
.section-avenir ul {
  list-style: inherit;
  margin-bottom: 10px;
}
.section-avenir ul > li {
  margin-bottom: 10px;
}
.section-avenir ul li::marker {
  margin-left: -30px;
}

.stats--graph:first-of-type {
  margin-top: 25px;
  margin-bottom: 25px;
}
.stats--graphs .stats--graph {
  border: 15px solid rgba(25, 33, 46, 0.1);
}
.stats--graph {
  display: block;
  position: relative;
  border-radius: 100%;
  margin: 0 auto;
  text-align: center;

  margin: 25px auto;
  max-width: 225px;
  width: 200px;
  height: 200px;
}
.graph--content {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  line-height: 1;
  flex-direction: column;
}
.stats--graph .number {
  /* font-family: Raleway; */
  font-size: 40px;
  /* font-weight: 100; */
  margin-bottom: 10px;
}
.graph--text {
  display: block;
  text-transform: uppercase;
  font-size: 14px;
}
.stats--graphs {
  border-radius: 5px;
}
.graph {
  width: 115%;
  height: 115%;
  position: absolute;
  top: -7.5%;
  left: -7.5%;
}
</style>
