<template>
    <div>
        <!-- Start Page Title -->
        <div class="page-title-area">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <h2>Blog Details</h2>
                    </div>
                </div>
			</div>
			
			<div class="shape1"><img src="../../../assets/img/shape1.png" alt="shape"></div>
			<div class="shape2 rotateme"><img src="../../../assets/img/shape2.svg" alt="shape"></div>
			<div class="shape3"><img src="../../../assets/img/shape3.svg" alt="shape"></div>
			<div class="shape4"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
			<div class="shape5"><img src="../../../assets/img/shape5.png" alt="shape"></div>
			<div class="shape6 rotateme"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
			<div class="shape7"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
			<div class="shape8 rotateme"><img src="../../../assets/img/shape2.svg" alt="shape"></div>
        </div>
        <!-- End Page Title -->

        <!-- Start Blog Details Area -->
        <section class="blog-details-area ptb-80">
            <div class="container">
                <div class="row">
                    <div class="col-lg-8 col-md-12">
                        <div class="blog-details-desc">
                            <div class="article-image">
                                <img src="../../../assets/img/blog-image/1.jpg" alt="image">
                            </div>

                            <div class="article-content">
                                <div class="entry-meta">
                                    <ul>
                                        <li><vue-feather type="clock"></vue-feather> <a href="#">September 31, 2022</a></li>
                                        <li><vue-feather type="user"></vue-feather> <a href="#">Steven Smith</a></li>
                                    </ul>
                                </div>

                                <h3>The security risks of changing package owners</h3>

                                <p>Quuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quia non numquam eius modi tempora incidunt ut labore et dolore magnam dolor sit amet, consectetur adipisicing.</p>

                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in  sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p>

                                <blockquote class="wp-block-quote">
                                    <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>

                                    <cite>Tom Cruise</cite>
                                </blockquote>

                                <p>Quuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quia non numquam eius modi tempora incidunt ut labore et dolore magnam dolor sit amet, consectetur adipisicing.</p>

                                <ul class="wp-block-gallery columns-3">
                                    <li class="blocks-gallery-item">
                                        <figure>
                                            <img src="../../../assets/img/blog-image/8.jpg" alt="image">
                                        </figure>
                                    </li>

                                    <li class="blocks-gallery-item">
                                        <figure>
                                            <img src="../../../assets/img/blog-image/7.jpg" alt="image">
                                        </figure>
                                    </li>

                                    <li class="blocks-gallery-item">
                                        <figure>
                                            <img src="../../../assets/img/blog-image/9.jpg" alt="image">
                                        </figure>
                                    </li>
                                </ul>

                                <h3>Four major elements that we offer:</h3>

                                <ul class="features-list">
                                    <li><vue-feather type="check"></vue-feather> Scientific Skills For getting a better result</li>
                                    <li><vue-feather type="check"></vue-feather> Communication Skills to getting in touch</li>
                                    <li><vue-feather type="check"></vue-feather> A Career Overview opportunity Available</li>
                                    <li><vue-feather type="check"></vue-feather> A good Work Environment For work</li>
                                </ul>

                                <h3>Setting the mood with incense</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in  sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p>

                                <h3>The Rise Of Smarketing And Why You Need It</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud.</p>
                            </div>

                            <div class="article-footer">
                                <div class="article-tags">
                                    <a href="#">Fashion</a>
                                    <a href="#">Smart</a>
                                    <a href="#">Marketing</a>
                                    <a href="#">Games</a>
                                    <a href="#">Travel</a>
                                </div>
                            </div>

                            <div class="startp-post-navigation">
                                <div class="prev-link-wrapper">
                                    <div class="info-prev-link-wrapper">
                                        <a href="#">
                                            <span class="image-prev">
                                                <img src="../../../assets/img/blog-image/2.jpg" alt="image">
                                                <span class="post-nav-title">Prev</span>
                                            </span>
        
                                            <span class="prev-link-info-wrapper">
                                                <span class="prev-title">Don't buy a tech gift until you read these rules</span>
                                                <span class="meta-wrapper">
                                                    <span class="date-post">January 21, 2022</span>
                                                </span>
                                            </span>
                                        </a>
                                    </div>
                                </div>
        
                                <div class="next-link-wrapper">
                                    <div class="info-next-link-wrapper">
                                        <a href="#">
                                            <span class="next-link-info-wrapper">
                                                <span class="next-title">The golden rule of buying a phone as a gift</span>
                                                <span class="meta-wrapper">
                                                    <span class="date-post">January 21, 2022</span>
                                                </span>
                                            </span>
        
                                            <span class="image-next">
                                                <img src="../../../assets/img/blog-image/3.jpg" alt="image">
                                                <span class="post-nav-title">Next</span>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div class="comments-area">
                                <h3 class="comments-title">2 Comments:</h3>

                                <ol class="comment-list">
                                    <li class="comment">
                                        <article class="comment-body">
                                            <footer class="comment-meta">
                                                <div class="comment-author vcard">
                                                    <img src="../../../assets/img/client-image/1.jpg" class="avatar" alt="image">
                                                    <b class="fn">John Jones</b>
                                                    <span class="says">says:</span>
                                                </div>

                                                <div class="comment-metadata">
                                                    <a href="#">
                                                        <time>April 24, 2019 at 10:59 am</time>
                                                    </a>
                                                </div>
                                            </footer>

                                            <div class="comment-content">
                                                <p>Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen.</p>
                                            </div>

                                            <div class="reply">
                                                <a href="#" class="comment-reply-link">Reply</a>
                                            </div>
                                        </article>

                                        <ol class="children">
                                            <li class="comment">
                                                <article class="comment-body">
                                                    <footer class="comment-meta">
                                                        <div class="comment-author vcard">
															<img src="../../../assets/img/client-image/2.jpg" class="avatar" alt="image">
                                                            <b class="fn">Steven Smith</b>
                                                            <span class="says">says:</span>
                                                        </div>
            
                                                        <div class="comment-metadata">
                                                            <a href="#">
                                                                <time>April 24, 2019 at 10:59 am</time>
                                                            </a>
                                                        </div>
                                                    </footer>
            
                                                    <div class="comment-content">
                                                        <p>Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen.</p>
                                                    </div>
            
                                                    <div class="reply">
                                                        <a href="#" class="comment-reply-link">Reply</a>
                                                    </div>
                                                </article>
                                            </li>

                                            <ol class="children">
                                                <li class="comment">
                                                    <article class="comment-body">
                                                        <footer class="comment-meta">
                                                            <div class="comment-author vcard">
																<img src="../../../assets/img/client-image/3.jpg" class="avatar" alt="image">
                                                                <b class="fn">Sarah Taylor</b>
                                                                <span class="says">says:</span>
                                                            </div>
                
                                                            <div class="comment-metadata">
                                                                <a href="#">
                                                                    <time>April 24, 2019 at 10:59 am</time>
                                                                </a>
                                                            </div>
                                                        </footer>
                
                                                        <div class="comment-content">
                                                            <p>Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen.</p>
                                                        </div>
                
                                                        <div class="reply">
                                                            <a href="#" class="comment-reply-link">Reply</a>
                                                        </div>
                                                    </article>
                                                </li>
                                            </ol>
                                        </ol>
                                    </li>

                                    <li class="comment">
                                        <article class="comment-body">
                                            <footer class="comment-meta">
                                                <div class="comment-author vcard">
                                                    <img src="../../../assets/img/client-image/4.jpg" class="avatar" alt="image">
                                                    <b class="fn">John Doe</b>
                                                    <span class="says">says:</span>
                                                </div>

                                                <div class="comment-metadata">
                                                    <a href="#">
                                                        <time>April 24, 2019 at 10:59 am</time>
                                                    </a>
                                                </div>
                                            </footer>

                                            <div class="comment-content">
                                                <p>Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen.</p>
                                            </div>

                                            <div class="reply">
                                                <a href="#" class="comment-reply-link">Reply</a>
                                            </div>
                                        </article>

                                        <ol class="children">
                                            <li class="comment">
                                                <article class="comment-body">
                                                    <footer class="comment-meta">
                                                        <div class="comment-author vcard">
															<img src="../../../assets/img/client-image/5.jpg" class="avatar" alt="image">
                                                            <b class="fn">James Anderson</b>
                                                            <span class="says">says:</span>
                                                        </div>
            
                                                        <div class="comment-metadata">
                                                            <a href="#">
                                                                <time>April 24, 2019 at 10:59 am</time>
                                                            </a>
                                                        </div>
                                                    </footer>
            
                                                    <div class="comment-content">
                                                        <p>Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen.</p>
                                                    </div>
            
                                                    <div class="reply">
                                                        <a href="#" class="comment-reply-link">Reply</a>
                                                    </div>
                                                </article>
                                            </li>
                                        </ol>
                                    </li>
                                </ol>

                                <div class="comment-respond">
                                    <h3 class="comment-reply-title">Leave a Reply</h3>

                                    <form class="comment-form">
                                        <p class="comment-notes">
                                            <span id="email-notes">Your email address will not be published.</span>
                                            Required fields are marked 
                                            <span class="required">*</span>
                                        </p>
                                        <p class="comment-form-comment">
                                            <label>Comment</label>
                                            <textarea name="comment" id="comment" cols="45" rows="5" maxlength="65525" required="required"></textarea>
                                        </p>
                                        <p class="comment-form-author">
                                            <label>Name <span class="required">*</span></label>
                                            <input type="text" id="author" name="author" required="required">
                                        </p>
                                        <p class="comment-form-email">
                                            <label>Email <span class="required">*</span></label>
                                            <input type="email" id="email" name="email" required="required">
                                        </p>
                                        <p class="comment-form-url">
                                            <label>Website</label>
                                            <input type="url" id="url" name="url">
                                        </p>
                                        <p class="comment-form-cookies-consent">
                                            <input type="checkbox" value="yes" name="wp-comment-cookies-consent" id="wp-comment-cookies-consent">
                                            <label for="wp-comment-cookies-consent">Save my name, email, and website in this browser for the next time I comment.</label>
                                        </p>
                                        <p class="form-submit">
                                            <input type="submit" name="submit" id="submit" class="submit" value="Post Comment">
                                        </p>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-12">
                        <aside class="widget-area" id="secondary">
                            <section class="widget widget_search">
                                <form class="search-form">
                                    <label>
                                        <span class="screen-reader-text">Search for:</span>
                                        <input type="search" class="search-field" placeholder="Search...">
                                    </label>
                                    <button type="submit"><vue-feather type="search"></vue-feather></button>
                                </form>
                            </section>

                            <section class="widget widget_startp_posts_thumb">
                                <h3 class="widget-title">Popular Posts</h3>

                                <article class="item">
                                    <a href="#" class="thumb">
                                        <span class="fullimage cover bg1" role="img"></span>
                                    </a>
                                    <div class="info">
                                        <time datetime="2019-06-30">June 10, 2019</time>
                                        <h4 class="title usmall"><a href="#">Making Peace With The Feast Or Famine Of Freelancing</a></h4>
                                    </div>

                                    <div class="clear"></div>
                                </article>

                                <article class="item">
                                    <a href="#" class="thumb">
                                        <span class="fullimage cover bg2" role="img"></span>
                                    </a>
                                    <div class="info">
                                        <time datetime="2019-06-30">June 21, 2019</time>
                                        <h4 class="title usmall"><a href="#">I Used The Web For A Day On A 50 MB Budget</a></h4>
                                    </div>

                                    <div class="clear"></div>
                                </article>

                                <article class="item">
                                    <a href="#" class="thumb">
                                        <span class="fullimage cover bg3" role="img"></span>
                                    </a>
                                    <div class="info">
                                        <time datetime="2019-06-30">June 30, 2019</time>
                                        <h4 class="title usmall"><a href="#">How To Create A Responsive Popup Gallery?</a></h4>
                                    </div>

                                    <div class="clear"></div>
                                </article>
                            </section>

                            <section class="widget widget_categories">
                                <h3 class="widget-title">Categories</h3>

                                <ul>
                                    <li><a href="#">Business</a></li>
                                    <li><a href="#">Privacy</a></li>
                                    <li><a href="#">Technology</a></li>
                                    <li><a href="#">Tips</a></li>
                                    <li><a href="#">Uncategorized</a></li>
                                </ul>
                            </section>

                            <section class="widget widget_archive">
                                <h3 class="widget-title">Archives</h3>

                                <ul>
                                    <li><a href="#">May 2019</a></li>
                                    <li><a href="#">April 2019</a></li>
                                    <li><a href="#">June 2019</a></li>
                                </ul>
                            </section>

                            <section class="widget widget_meta">
                                <h3 class="widget-title">Meta</h3>

                                <ul>
                                    <li><a href="#">Log in</a></li>
                                    <li><a href="#">Entries <abbr title="Really Simple Syndication">RSS</abbr></a></li>
                                    <li><a href="#">Comments <abbr title="Really Simple Syndication">RSS</abbr></a></li>
                                    <li><a href="#">WordPress.org</a></li>
                                </ul>
                            </section>

                            <section class="widget widget_tag_cloud">
                                <h3 class="widget-title">Tags</h3>

                                <div class="tagcloud">
                                    <a href="#">IT <span class="tag-link-count"> (3)</span></a>
                                    <a href="#">Spacle <span class="tag-link-count"> (3)</span></a>
                                    <a href="#">Games <span class="tag-link-count"> (2)</span></a>
                                    <a href="#">Fashion <span class="tag-link-count"> (2)</span></a>
                                    <a href="#">Travel <span class="tag-link-count"> (1)</span></a>
                                    <a href="#">Smart <span class="tag-link-count"> (1)</span></a>
                                    <a href="#">Marketing <span class="tag-link-count"> (1)</span></a>
                                    <a href="#">Tips <span class="tag-link-count"> (2)</span></a>
                                </div>
                            </section>
                        </aside>
                    </div>
                </div>
            </div>
        </section>
        <!-- End Blog Details Area -->
    </div>
</template>

<script>
export default {
    name: 'BlogDetails'
}
</script>