<template>
  <div class="works-area ptb-80 bg-f7fafd">
    <div class="container">
      <div class="section-title">
        <h2 class="text-uppercase">
          <well-formatted-title
            blackArea="Domaines d'expertise"
            :lineBreak="false"
          />
        </h2>
        <div class="bar"></div>
        <div class="tw-text-slate-700 tw-max-w-140 mx-auto">
          Notre SSII répond à tous vos besoins technologiques grâce à la
          diversité de nos domaines d’expertise et à l’excellence de nos
          collaborateurs.
        </div>
      </div>
    </div>

    <div class="px-5">
      <div class="row">
        <div
          class="col-lg-3 col-sm-6 col-md-6"
          v-for="slide in carouselItems"
          :key="slide.id"
        >
          <div
            class="single-ml-services-box shadow-sm tw-items-center tw-justify-center d-flex tw-flex-col"
          >
            <div class="image tw-h-45 tw-flex">
              <div class="tw-my-auto">
                <img :src="slide.image" alt="image" />
              </div>
            </div>
            <h3 class="p-0 m-0">
              <a href="#">{{ slide.title }}</a>
            </h3>
          </div>
        </div>
      </div>
    </div>

    <div class="shape8 rotateme">
      <img src="../../../assets/img/shape2.svg" alt="shape" />
    </div>
    <div class="shape2 rotateme">
      <img src="../../../assets/img/shape2.svg" alt="shape" />
    </div>
    <div class="shape7">
      <img src="../../../assets/img/shape4.svg" alt="shape" />
    </div>
    <div class="shape4">
      <img src="../../../assets/img/shape4.svg" alt="shape" />
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
//import { Carousel, Slide, Navigation } from "vue3-carousel";

import "vue3-carousel/dist/carousel.css";
import WellFormattedTitle from "../../generics/Typography/Divider/WellFormattedTitle.vue";

export default defineComponent({
  name: "DomaineExpertise",
  components: {
    WellFormattedTitle,
  },
  data: () => ({
    settings: {
      itemsToShow: 1,
      snapAlign: "center",
      infinite: true,
      wrapAround: true,
      // autoplay: 2000,
    },
    carouselItems: [
      {
        id: 1,
        image: require("../../../assets/img/services-image/4.png"),
        title: "Infrastructures",
        desc: "Windows, Linux, Poste de travail, Virtualisation..",
      },
      {
        id: 2,
        image: require("../../../assets/img/services-image/2.png"),
        title: "Production",
        desc: "Windows, Linux, Poste de travail, Virtualisation..",
      },
      {
        id: 3,
        image: require("../../../assets/img/services-image/6.png"),
        title: "Cloud",
        desc: "AMAZONE WS, AZURE, GCP, OVH, ALIBABA, IBM, Oracle...",
      },
      {
        id: 4,
        //require("../../../assets/img/accueil-images/devops.jpg")
        image: require("../../../assets/img/accueil-images/devsecops.jpg"),
        title: "DevOps",
        desc: "JENKINS, KUBERNETES, DOCKER, MAVEN, NGINX, NAGIOS, ANSIBLE, TERRAFORM...",
      },
    ],
    breakpoints: {
      0: {
        itemsToShow: 1,
        snapAlign: "center",
      },
      768: {
        itemsToShow: 2,
        snapAlign: "center",
      },
      1200: {
        itemsToShow: 3,
        snapAlign: "center",
      },
      1500: {
        itemsToShow: 3,
        snapAlign: "center",
      },
    },
  }),
});
</script>
