<template>
    <div>
        <!-- Start Page Title -->
        <div class="page-title-area">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <h2>Services</h2>
                    </div>
                </div>
			</div>
			
			<div class="shape1"><img src="../../../assets/img/shape1.png" alt="shape"></div>
			<div class="shape2 rotateme"><img src="../../../assets/img/shape2.svg" alt="shape"></div>
			<div class="shape3"><img src="../../../assets/img/shape3.svg" alt="shape"></div>
			<div class="shape4"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
			<div class="shape5"><img src="../../../assets/img/shape5.png" alt="shape"></div>
			<div class="shape6 rotateme"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
			<div class="shape7"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
			<div class="shape8 rotateme"><img src="../../../assets/img/shape2.svg" alt="shape"></div>
        </div>
        <!-- End Page Title -->

        <!-- Start Services Area -->
        <div class="agency-services-area ptb-80">
            <div class="container">
                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-md-6">
                        <div class="agency-services-box">
                            <img src="../../../assets/img/agency-image/services-img1.jpg" alt="image">

                            <div class="content">
                                <h3><a href="#">IT Professionals</a></h3>
								<a href="#" class="read-more-btn">Read More <vue-feather type="plus-circle"></vue-feather></a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-6 col-md-6">
                        <div class="agency-services-box">
                            <img src="../../../assets/img/agency-image/services-img2.jpg" alt="image">

                            <div class="content">
                                <h3><a href="#">Software Engineers</a></h3>
								<a href="#" class="read-more-btn">Read More <vue-feather type="plus-circle"></vue-feather></a>
                            </div>
                        </div>
                    </div>
                    
                    <div class="col-lg-4 col-sm-6 col-md-6">
                        <div class="agency-services-box">
                            <img src="../../../assets/img/agency-image/services-img3.jpg" alt="image">

                            <div class="content">
                                <h3><a href="#">Web Development</a></h3>
								<a href="#" class="read-more-btn">Read More <vue-feather type="plus-circle"></vue-feather></a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-md-6">
                        <div class="agency-services-box">
                            <img src="../../../assets/img/agency-image/services-img4.jpg" alt="image">

                            <div class="content">
                                <h3><a href="#">SEO & Content</a></h3>
								<a href="#" class="read-more-btn">Read More <vue-feather type="plus-circle"></vue-feather></a>
                            </div>
                        </div>
                    </div>
                    
                    <div class="col-lg-4 col-sm-6 col-md-6">
                        <div class="agency-services-box">
                            <img src="../../../assets/img/agency-image/services-img5.jpg" alt="image">

                            <div class="content">
                                <h3><a href="#">Digital Marketing</a></h3>
								<a href="#" class="read-more-btn">Read More <vue-feather type="plus-circle"></vue-feather></a>
                            </div>
                        </div>
                    </div>
                    
                    <div class="col-lg-4 col-sm-6 col-md-6">
                        <div class="agency-services-box">
                            <img src="../../../assets/img/agency-image/services-img6.jpg" alt="image">

                            <div class="content">
                                <h3><a href="#">Data Analysts</a></h3>
								<a href="#" class="read-more-btn">Read More <vue-feather type="plus-circle"></vue-feather></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

			<div class="shape2 rotateme"><img src="../../../assets/img/shape2.svg" alt="shape"></div>
			<div class="shape3"><img src="../../../assets/img/shape3.svg" alt="shape"></div>
			<div class="shape4"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
			<div class="shape6 rotateme"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
			<div class="shape7"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
            <div class="shape8 rotateme"><img src="../../../assets/img/shape2.svg" alt="shape"></div>
        </div>
        <!-- End Services Area -->
    </div>
</template>

<script>
export default {
    name: 'ServicesFive'
}
</script>