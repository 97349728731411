<template>
  <div class="agency-portfolio-about-area ptb-80">
    <div class="container">
      <div class="row">
        <div class="col-lg-4 col-md-6 col-sm-6">
          <div class="single-text-box">
            <img
              src="../../../assets/img/agency-portfolio-about-image/img1.jpg"
              alt="image"
            />
            <h3>About Us</h3>
            <p>
              Vestibulum ac posuere mi. Nullam euismod dui condimentum metus
              consequat blandit. Donec leo tortor, commodo eget fermentum id,
              molestie quis est. Ut lectus metus, condimentum eget facilisis
              sed, fermentum et tortor.
            </p>
            <a href="#" class="learn-more-btn"
              ><vue-feather type="plus-circle"></vue-feather>Learn More</a
            >
          </div>
        </div>

        <div class="col-lg-4 col-md-6 col-sm-6">
          <div class="single-text-box">
            <img
              src="../../../assets/img/agency-portfolio-about-image/img2.jpg"
              alt="image"
            />
            <h3>Our Vision</h3>
            <p>
              Vestibulum ac posuere mi. Nullam euismod dui condimentum metus
              consequat blandit. Donec leo tortor, commodo eget fermentum id,
              molestie quis est. Ut lectus metus, condimentum eget facilisis
              sed, fermentum et tortor.
            </p>
            <a href="#" class="learn-more-btn"
              ><vue-feather type="plus-circle"></vue-feather>Learn More</a
            >
          </div>
        </div>

        <div
          class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3"
        >
          <div class="single-text-box">
            <img
              src="../../../assets/img/agency-portfolio-about-image/img3.jpg"
              alt="image"
            />
            <h3>Our Mission</h3>
            <p>
              Vestibulum ac posuere mi. Nullam euismod dui condimentum metus
              consequat blandit. Donec leo tortor, commodo eget fermentum id,
              molestie quis est. Ut lectus metus, condimentum eget facilisis
              sed, fermentum et tortor.
            </p>
            <a href="#" class="learn-more-btn"
              ><vue-feather type="plus-circle"></vue-feather>Learn More</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutUs",
};
</script>