<template>
  <span class="tw-text-[35px] tw-font-extrabold tw-leading-[37px]">
    <span class="tw-text-slate-800 me-2"> {{ blackArea }} </span>
    <br v-if="lineBreak" />
    <span class="tw-text-wdata-orange">
      {{ orangeArea }}
    </span>
  </span>
</template>

<script>
export default {
  name: "WellFormattedTitle",
  props: {
    lineBreak: {
      type: Boolean,
      default: true,
    },
    blackArea: String,
    orangeArea: String,
  },
};
</script>
