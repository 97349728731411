<template>
  <div class="repair-cta-area bg-0f054b">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6">
          <div class="cta-repair-content">
            <h3>We'll help you get back to work</h3>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis
              ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas
              accumsan lacus vel facilisis.
            </p>
            <a href="#" class="btn btn-primary">Contact Us</a>
          </div>
        </div>

        <div class="col-lg-6">
          <div class="cta-repair-img">
            <img src="../../../assets/img/man-and-women.png" alt="image" />
          </div>
        </div>
      </div>
    </div>

    <div class="circle-box">
      <img src="../../../assets/img/circle.png" alt="image" />
    </div>
    <div class="cta-shape">
      <img src="../../../assets/img/cta-shape.png" alt="image" />
    </div>
  </div>
</template>

<script>
export default {
  name: "CtaRepair",
};
</script>