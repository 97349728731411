<template>
    <div class="blog-area ptb-80">
			<div class="container">
				<div class="section-title">
					<h2>Our Recent Story</h2>
					<div class="bar"></div>
					<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                </div>
                
                <div class="row">
                    <div class="col-lg-4 col-md-6">
                        <div class="single-blog-post-box bg-f4faff">
                            <div class="entry-thumbnail">
                                <a href="#"><img src="../../../assets/img/blog-image/1.jpg" alt="image"></a>
                            </div>

                            <div class="entry-post-content">
                                <div class="entry-meta">
                                    <ul>
                                        <li><a href="#">Admin</a></li>
                                        <li>August 15, 2019</li>
                                    </ul>
                                </div>

                                <h3><a href="#">Making Peace With The Feast Or Famine Of Freelancing</a></h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod...</p>
                                <a href="#" class="learn-more-btn">Read Story <vue-feather type="plus"></vue-feather></a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6">
                        <div class="single-blog-post-box bg-f4faff">
                            <div class="entry-thumbnail">
                                <a href="#"><img src="../../../assets/img/blog-image/2.jpg" alt="image"></a>
                            </div>

                            <div class="entry-post-content">
                                <div class="entry-meta">
                                    <ul>
                                        <li><a href="#">Admin</a></li>
                                        <li>August 18, 2019</li>
                                    </ul>
                                </div>

                                <h3><a href="#">I Used The Web For A Day On A 50 MB Budget</a></h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod...</p>
                                <a href="#" class="learn-more-btn">Read Story <vue-feather type="plus"></vue-feather></a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                        <div class="single-blog-post-box bg-f4faff">
                            <div class="entry-thumbnail">
                                <a href="#"><img src="../../../assets/img/blog-image/3.jpg" alt="image"></a>
                            </div>

                            <div class="entry-post-content">
                                <div class="entry-meta">
                                    <ul>
                                        <li><a href="#">Admin</a></li>
                                        <li>August 15, 2019</li>
                                    </ul>
                                </div>

                                <h3><a href="#">Here are the 5 most telling signs of micromanagement</a></h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod...</p>
                                <a href="#" class="learn-more-btn">Read Story <vue-feather type="plus"></vue-feather></a>
                            </div>
                        </div>
                    </div>
                </div>
			</div>

			<div class="shape1"><img src="../../../assets/img/shape1.png" alt="shape"></div>
			<div class="shape2 rotateme"><img src="../../../assets/img/shape2.svg" alt="shape"></div>
			<div class="shape3"><img src="../../../assets/img/shape3.svg" alt="shape"></div>
			<div class="shape4"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
			<div class="shape6 rotateme"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
			<div class="shape7"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
			<div class="shape8 rotateme"><img src="../../../assets/img/shape2.svg" alt="shape"></div>
        </div>
</template>

<script>
export default {
    name: 'OurRecentStory'
}
</script>