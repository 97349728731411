<template>
  <div class="iot-features-area ptb-80 bg-f7fafd">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6 iot-features-content">
          <div class="section-title mb-4">
            <h2 class="text-start">
              <well-formatted-title
                blackArea="Notre Politique RSE"
                :lineBreak="false"
              />
            </h2>
          </div>
          <div class="tw-text-slate-700">
            Fortement convaincue par les principes du Pacte mondial des Nations
            Unies et les Objectifs de Développement Durable (ODD),
            {{ APP_NAME }}
            s’engage résolument dans une démarche RSE pérenne, axée sur le
            bien-être et la confiance mutuelle avec tous ses partenaires:
            clients, fournisseurs, sous-traitants, pouvoirs publics et, avant
            tout, ses collaborateurs.
          </div>
          <div class="pt-3 tw-text-slate-700">
            Nos initiatives RSE, intégrées naturellement à nos activités
            quotidiennes, visent à améliorer continuellement l’impact social,
            sociétal et environnemental de l’entreprise et de son entourage,
            tout en optimisant notre performance financière. Nous prônons la
            durabilité et adoptons des pratiques de Green IT et de sobriété
            numérique pour garantir un numérique responsable. Chez
            {{ APP_NAME }}, nous croyons fermement que la valeur inclut
            l’humain, la planète et le progrès économique. La responsabilité
            sociétale est pour nous indissociable de la réussite économique, et
            nous nous engageons à transformer notre modèle économique d’une
            économie de volume à une économie de valeurs.
          </div>

          <router-link
            to="/engagement-rse"
            class="btn btn-primary w-100 text-uppercase mt-4"
          >
            Découvrir notre politique RSE
          </router-link>
        </div>

        <div class="col-lg-6 iot-features-image">
          <div class="justify-content-center d-flex">
            <img
              src="../../../assets/img/iot-features-image/1.png"
              alt="image"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { APP_NAME } from "../../../statics/app_static";
import WellFormattedTitle from "../../generics/Typography/Divider/WellFormattedTitle.vue";
export default {
  components: { WellFormattedTitle },
  data() {
    return {
      APP_NAME,
    };
  },
  name: "PolitiqueRse",
};
</script>
