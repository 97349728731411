<template>
    <div class="services-area ptb-80">
        <div class="container">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-12 services-left-image single-left-image">
                    <img src="../../../assets/img/services-left-image/cercle-shape.png" class="bg-image rotateme" alt="shape">

                    <img src="../../../assets/img/services-left-image/main-pic.png" alt="main-pic">
                </div>

                <div class="col-lg-6 col-md-12 services-content">
                    <div class="section-title">
                        <h2>Design & Development</h2>
                        <div class="bar"></div>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    </div>

                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="box">
                                <vue-feather type="layout"></vue-feather> Responsive design
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="box">
                                <vue-feather type="code"></vue-feather> React web development
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="box">
                                <vue-feather type="smartphone"></vue-feather> Android apps development
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="box">
                                <vue-feather type="code"></vue-feather> Laravel web development
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="box">
                                <vue-feather type="smartphone"></vue-feather> iOS apps development
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="box">
                                <vue-feather type="pen-tool"></vue-feather> UX/UI design
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="box">
                                <vue-feather type="shopping-cart"></vue-feather> E-commerce development
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="box">
                                <vue-feather type="check-circle"></vue-feather> Print ready design
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ServicesArea'
}
</script>