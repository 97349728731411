<template>
  <div class="d-flex tw-gap-2.5">
    <div
      class="tw-w-31 tw-h-13 d-flex justify-content-end flex-column -tw-mt-6"
      :class="{ 'tw-invisible': direction != 'left' }"
    >
      <div
        class="text-uppercase tw-font-bold tw-text-wdata-blue tw-leading-3 tw-pb-2.5 tw-text-xs"
      >
        {{ label }}
      </div>
      <div class="tw-w-full tw-h-1 tw-bg-wdata-blue"></div>
    </div>
    <div class="col-2 p-0 d-flex flex-column align-items-center">
      <div
        class="circle tw-rounded-full tw-w-13 tw-h-13 tw-bg-wdata-blue d-flex justify-content-center align-items-center tw-text-wdata-orange"
      >
        <img v-if="icon" :src="icon" class="tw-w-6 tw-h-6" />
      </div>
      <div
        class="tw-w-1 tw-h-9 tw-bg-wdata-blue tw-my-2.5"
        v-if="!isFinalLine"
      ></div>
    </div>

    <div
      class="tw-w-31 tw-h-13 d-flex justify-content-end flex-column -tw-mt-6"
      :class="{ 'tw-invisible': direction != 'right' }"
    >
      <div
        class="text-uppercase tw-font-bold tw-text-wdata-blue tw-leading-3 tw-pb-2.5 tw-text-xs text-end"
      >
        {{ label }}
      </div>
      <div class="tw-w-full tw-h-1 tw-bg-wdata-blue"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SideTimeLineItem",

  props: {
    direction: {
      type: String,
      default: "left", // or 'right'
    },
    isFinalLine: {
      type: Boolean,
      default: false,
    },
    label: String,
    icon: Object,
  },
};
</script>
