<template>
    <!-- Start Boxes Area -->
    <section class="boxes-area">
        <div class="container">
            <div class="row">
                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="single-box">
                        <div class="icon">
                            <vue-feather type="server"></vue-feather>
                        </div>
                        <h3>Zero Configuration</h3>
                        <p>Lorem ipsum dolor sit amet elit, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna.</p>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="single-box bg-f78acb">
                        <div class="icon">
                            <vue-feather type="code"></vue-feather>
                        </div>
                        <h3>Code Security</h3>
                        <p>Lorem ipsum dolor sit amet elit, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna.</p>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="single-box bg-c679e3">
                        <div class="icon">
                            <vue-feather type="users"></vue-feather>
                        </div>
                        <h3>Team Management</h3>
                        <p>Lorem ipsum dolor sit amet elit, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna.</p>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="single-box bg-eb6b3d">
                        <div class="icon">
                            <vue-feather type="git-branch"></vue-feather>
                        </div>
                        <h3>Access Controlled</h3>
                        <p>Lorem ipsum dolor sit amet elit, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- End Boxes Area -->
</template>

<script>
export default {
    name: 'BoxesArea'
}
</script>