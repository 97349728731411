<template>
  <div class="tw-pt-10 tw-mt-22.5">
    <div class="lg:tw-px-[140px] tw-px-5 pb-5">
      <div class="row">
        <div class="col-lg-6 section-avenir">
          <p class="tw-text-[35px] tw-font-extrabold tw-leading-[37px]">
            <span class="tw-text-slate-950"> Rejoignez-nous </span>
            <br />
            <span class="tw-text-wdata-orange">
              L’humain au cœur de l’expertise
            </span>
          </p>
          <div
            class="tw-my-3 tw-h-0.5 tw-bg-[#19212e] tw-border-opacity-50 tw-w-32"
          ></div>
          <p class="tw-py-6 tw-text-slate-700">
            Au-delà des compétences techniques, nous recherchons des individus
            passionnés, motivés et dotés d'un solide sens de l'éthique
            professionnelle. <br />
            Nous mettons en avant la diversité, l'inclusion et le respect mutuel
            au sein de notre équipe. <br />
            C'est pourquoi nous pratiquons un recrutement sélectif basé sur
            plusieurs critères, tels que les compétences, le potentiel, les
            valeurs et un fort engagement en termes de service.<br />
            Notre culture d'entreprise encourage la formation continue, le
            mentorat et la constante acquisition de nouvelles compétences,
            offrant ainsi à chacun la possibilité de s'épanouir et de réaliser
            pleinement son potentiel.
          </p>
          <p class="tw-py-6 tw-font-bold">
            Nous attachons une importance capitale à la personne au-delà de
            l’expertise.
          </p>
        </div>

        <div class="col-lg-6 justify-content-center d-flex">
          <side-time-line class="tw-mt-4" />
        </div>
      </div>
    </div>
    <nos-expertises-solutions />
  </div>
</template>

<script>
import { APP_NAME } from "../../statics/app_static";
import NosExpertisesSolutions from "./accueil-components/NosExpertisesSolutions/NosExpertisesSolutions.vue";
import SideTimeLine from "./JoinUs/SideTimeLine.vue";
export default {
  name: "JoinUs",
  components: {
    NosExpertisesSolutions,
    SideTimeLine,
  },
  data() {
    return {
      APP_NAME,
    };
  },
};
</script>
