<template>
  <div class="pt-5">
    <SimpleSectionHeader title="Mentions légales" isSectionTitle />
    <div class="container section-politiques">
      <!-- Bloc 1 -->
      <CardWithLineGeneric>
        <template v-slot:title>
          <vue-feather type="check" class="tw-text-orange-500 me-3" />
          Directeur de publication
        </template>
        <template v-slot:body>
          <p>Ahmed BEN AMOR - {{ APP_NAME }} <br />{{ APP_EMAIL }}</p>
          <ul>
            <li>6 Rue du 4 septembre</li>
            <li>92130 Issy les moulineaux</li>
            <li>Tél. 01 71 50 10 11</li>
          </ul>
        </template>
      </CardWithLineGeneric>
      <!-- Bloc 2 -->
      <CardWithLineGeneric>
        <template v-slot:title>
          <vue-feather type="check" class="tw-text-orange-500 me-3" />
          Hébergement
        </template>
        <template v-slot:body>
          <p>worldata-team.com est hébergé par OVH SAS</p>
          <ul>
            <li>RCS Lille 537 407 926 sise 2</li>
            <li>Code APE 2620Z</li>
            <li>N° TVA : FR 22 424 761 419</li>
            <li>Siège social : 2 rue Kellermann – 59100 Roubaix – France.</li>
          </ul>
        </template>
      </CardWithLineGeneric>

      <!-- Bloc 3 -->
      <CardWithLineGeneric>
        <template v-slot:title>
          <vue-feather type="check" class="tw-text-orange-500 me-3" />
          Crédit photos
        </template>
        <template v-slot:body>@worldata-team</template>
      </CardWithLineGeneric>
      <!-- Bloc 4 -->
      <CardWithLineGeneric>
        <template v-slot:title>
          <vue-feather type="check" class="tw-text-orange-500 me-3" />
          Design et développement
        </template>
        <template v-slot:body>@worldata-team</template>
      </CardWithLineGeneric>
    </div>
  </div>
</template>

<script>
import {
  APP_ADDRESS,
  APP_EMAIL,
  APP_NAME,
  APP_PHONE,
} from "../../statics/app_static";
import CardWithLineGeneric from "../generics/Cards/CardWithLine/CardWithLineGeneric.vue";
import SimpleSectionHeader from "../generics/Headers/SimpleSectionHeader/SimpleSectionHeader.vue";

export default {
  name: "MentionsLegales",
  components: {
    SimpleSectionHeader,
    CardWithLineGeneric,
  },
  data() {
    return {
      APP_NAME,
      APP_ADDRESS,
      APP_EMAIL,
      APP_PHONE,
    };
  },
};
</script>
