<template>
  <span ref="counter">
    {{ inc }}
  </span>
</template>

<script>
export default {
  name: "CounterVue",
  props: {
    targetVal: {
      type: Number,
      default: 10,
    },
  },
  data() {
    return {
      inc: 0,
      intervalId: null, // Store interval ID
      observer: null, // Store observer
    };
  },
  mounted() {
    // Create an Intersection Observer
    this.observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.startCounter(50); // Start with 50 ms interval when visible
        } else {
          clearInterval(this.intervalId); // Stop the counter when not visible
        }
      });
    }); // Observe the counter element

    this.observer.observe(this.$refs.counter);
  },
  methods: {
    startCounter(interval) {
      this.intervalId = setInterval(() => {
        if (this.inc >= this.targetVal) {
          clearInterval(this.intervalId);
          return;
        }

        this.inc++;

        if (this.inc >= this.targetVal * 0.9 && interval !== 100) {
          clearInterval(this.intervalId);
          this.startCounter(100); // Change to 100 ms interval
        }
      }, interval);
    },
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
    if (this.observer) {
      this.observer.disconnect(); // Disconnect the observer when the component is destroyed
    }
  },
};
</script>
