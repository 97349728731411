<template>
    <div class="iot-main-banner">
        <div class="container">
            <div class="iot-banner-content">
                <span>Internet of Things</span>
                <h2>We get it! IoT growth is happening</h2>
                <p>At this point, it may seem like overkill to point out the continued growth bound to happen in the Internet of Things space for the years to come and how it will create new opportunities for companies, both established and new, from a hardware and software perspective.</p>
                <a href="#" class="btn btn-primary">Get Started</a>
            </div>

            <div class="iot-banner-image">
                <img src="../../../assets/img/iot-banner-image/1.png" alt="image">
                <img src="../../../assets/img/iot-banner-image/2.png" alt="image">
            </div>

            <div class="animate-border">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MainBanner'
}
</script>