<template>
  <div class="funfacts-area ptb-80">
    <div class="container">
      <div class="section-title">
        <h2>We always try to understand users expectation</h2>
        <div class="bar"></div>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </p>
      </div>

      <div class="row">
        <div class="col-lg-3 col-md-3 col-6 col-sm-3">
          <div class="funfact">
            <h3>10+</h3>
            <p>Downloaded</p>
          </div>
        </div>

        <div class="col-lg-3 col-md-3 col-6 col-sm-3">
          <div class="funfact">
            <h3>8+</h3>
            <p>Feedback</p>
          </div>
        </div>

        <div class="col-lg-3 col-md-3 col-6 col-sm-3">
          <div class="funfact">
            <h3>70+</h3>
            <p>Workers</p>
          </div>
        </div>

        <div class="col-lg-3 col-md-3 col-6 col-sm-3">
          <div class="funfact">
            <h3>111+</h3>
            <p>Contributors</p>
          </div>
        </div>
      </div>

      <div class="contact-cta-box">
        <h3>Have any question about us?</h3>
        <p>Don't hesitate to contact us</p>
        <a href="#" class="btn btn-primary">Contact Us</a>
      </div>

      <div class="map-bg">
        <img src="../../../assets/img/map.png" alt="map" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FunFacts",
};
</script>