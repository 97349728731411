<template>
  <div class="form-group">
    <label v-if="label">{{ label }}</label>
    <textarea
      :name="name"
      :id="label"
      class="form-control h-160"
      required
      v-model="inputValue"
      v-if="type == 'textarea'"
      :data-error="errorMsg"
      :placeholder="placeholder ?? 'Votre ' + label + ' ici ...'"
    ></textarea>
    <input
      :type="type"
      :name="name"
      :id="name"
      v-else
      class="form-control"
      required
      v-model="inputValue"
      :data-error="errorMsg"
      :accept="accept"
      :placeholder="placeholder ?? 'Votre ' + label.toLowerCase() + ' ici ...'"
    />

    <div class="help-block with-errors">
      {{ errorMsgHelper }}
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, computed } from "vue";

const props = defineProps({
  label: String,
  placeholder: String,
  type: {
    type: String,
    default: "text",
  },
  name: String,
  errorMsg: String,
  errorMsgHelper: String,
  modelValue: String,
  accept: String,
});

const emit = defineEmits(["update:modelValue"]);
const inputValue = computed({
  get: () => props.modelValue,
  set: (value) => emit("update:modelValue", value),
});
</script>
