<template>
  <div class="bigdata-feedback-area ptb-80">
    <div class="container">
      <div class="section-title">
        <h2>What Users Saying</h2>
        <div class="bar"></div>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </p>
      </div>

      <div class="row">
        <div class="col-lg-4 col-sm-6 col-md-6">
          <div class="single-feedback-box">
            <div class="feedback-desc">
              <p>
                Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                accus antium doloremque laudantium, totam rem aperiam, eaque
                ipsaquaeab illo.
              </p>
            </div>

            <div class="client-info">
              <img src="../../../assets/img/author1.jpg" alt="image" />
              <h3>David Luis</h3>
              <span>Founder & CEO</span>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-sm-6 col-md-6">
          <div class="single-feedback-box">
            <div class="feedback-desc">
              <p>
                Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                accus antium doloremque laudantium, totam rem aperiam, eaque
                ipsaquaeab illo.
              </p>
            </div>

            <div class="client-info">
              <img src="../../../assets/img/author2.jpg" alt="image" />
              <h3>Steven Smith</h3>
              <span>Developer</span>
            </div>
          </div>
        </div>

        <div
          class="col-lg-4 col-sm-6 col-md-6 offset-lg-0 offset-md-3 offset-sm-3"
        >
          <div class="single-feedback-box">
            <div class="feedback-desc">
              <p>
                Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                accus antium doloremque laudantium, totam rem aperiam, eaque
                ipsaquaeab illo.
              </p>
            </div>

            <div class="client-info">
              <img src="../../../assets/img/author3.jpg" alt="image" />
              <h3>Sarah Lucy</h3>
              <span>Designer</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ClientsFeedback",
};
</script>