<template>
  <button type="button" class="btn btn-primary" :class="getButtonSize()">
    <slot></slot>
  </button>
</template>

<script>
//import './Button.css'
export default {
  name: "ButtonComponent",
  props: {
    size: {
      type: String,
      default: "s",
    },
  },
  methods: {
    getButtonSize() {
      const buttonsSizesClassMap = { s: "py-2 px-3", m: "", l: "" };

      return buttonsSizesClassMap[this.size] ?? "";
    },
  },
};
</script>
