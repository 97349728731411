<template>
  <section class="solutions-area ptb-80 tw-bg-wdata-blue">
    <div class="container">
      <div class="row text-uppercase">
        <div class="col-lg-6 col-sm-6 col-md-6">
          <div class="single-solutions-box">
            <div class="icon">
              <img src="../../../../assets/img/icon4.png" alt="image" />
            </div>
            <h3>
              <router-link to="/contact"
                >Besoin de renseignements ?</router-link
              >
            </h3>

            <router-link to="/contact" class="learn-more-btn tw-text-blue-400">
              Contactez-nous
            </router-link>
          </div>
        </div>

        <div class="col-lg-6 col-sm-6 col-md-6">
          <div class="single-solutions-box">
            <div class="icon">
              <img src="../../../../assets/img/icon5.png" alt="image" />
            </div>
            <h3>
              <router-link to="/nos-offres"
                >Challengez votre carrière</router-link
              >
            </h3>

            <router-link
              to="/nos-offres"
              class="learn-more-btn tw-text-wdata-orange"
            >
              {{ countPostes }} postes sont à pourvoir</router-link
            >
          </div>
        </div>
      </div>
    </div>

    <div class="shape1">
      <img src="../../../../assets/img/shape1.png" alt="shape" />
    </div>
    <div class="shape2 rotateme">
      <img src="../../../../assets/img/shape2.svg" alt="shape" />
    </div>
    <div class="shape3">
      <img src="../../../../assets/img/shape3.svg" alt="shape" />
    </div>
    <div class="shape4">
      <img src="../../../../assets/img/shape4.svg" alt="shape" />
    </div>
    <div class="shape6 rotateme">
      <img src="../../../../assets/img/shape4.svg" alt="shape" />
    </div>
    <div class="shape7">
      <img src="../../../../assets/img/shape4.svg" alt="shape" />
    </div>
    <div class="shape8 rotateme">
      <img src="../../../../assets/img/shape2.svg" alt="shape" />
    </div>
  </section>
</template>

<script>
import { BACK_URL } from "../../../../statics/app_static";
export default {
  name: "NosExpertisesSolutions",
  data() {
    return {
      countPostes: 0,
    };
  },
  mounted() {
    this.getCountPostes();
  },
  methods: {
    getCountPostes() {
      fetch(BACK_URL + "/api/jobs/count")
        .then((res) => res.text())
        .then((res) => {
          this.countPostes = res;
        })
        .catch((e) => {
          console.error(e);
        });
    },
  },
};
</script>
