<template>
  <div class="pt-5">
    <SimpleSectionHeader
      title="RGPD : Gestion opérations de recrutement."
      class="pb-0 tw-pt-30"
      isSectionTitle
    />
    <div class="container section-politiques">
      <CardWithLineGeneric>
        <template v-slot:body>
          <p>
            {{ APP_NAME }}, responsable du traitement, met en œuvre un
            traitement de données à caractère personnel pour la gestion des
            opérations de recrutement.
          </p>
          <p>
            Le traitement de ces données a pour finalité d’évaluer les aptitudes
            professionnelles du candidat et sa capacité à occuper l’emploi
            proposé. Elles sont destinées aux services concernés de la société
            {{ APP_NAME }}. Le recueil de vos données est réalisé sur la base de
            votre consentement. La base légale du traitement réalisé par
            {{ APP_NAME }} est l'intérêt légitime. Les données collectées sont
            indispensables à ce traitement. A défaut, votre demande pourrait ne
            pas être traitée ou son traitement pourrait être retardé. Vos
            données personnelles sont conservées 2 ans suivant le dernier
            contact.
          </p>
        </template>
      </CardWithLineGeneric>

      <CardWithLineGeneric>
        <template v-slot:body>
          Le candidat est informé que, le cas échéant, une recherche de
          références sera effectuée dans son environnement professionnel auprès
          d’autres employeurs, de collègues, de supérieurs hiérarchiques, de
          maîtres de stages, de tuteurs, de clients ou de fournisseurs. Par
          ailleurs des tests d’aptitudes professionnelles ou psychotechniques,
          dont les résultats resteront confidentiels, peuvent être demandés au
          candidat.
        </template>
      </CardWithLineGeneric>

      <CardWithLineGeneric>
        <template v-slot:body>
          En application de la réglementation en vigueur, le candidat dispose
          d’un droit d’accès (y compris les résultats des analyses, tests,
          examens et évaluations en découlant), de rectification, d'effacement,
          de demander une limitation du traitement de ses données personnelles,
          le droit à la portabilité de ses données et d'opposition au traitement
          pour motifs légitimes.
        </template>
      </CardWithLineGeneric>

      <CardWithLineGeneric>
        <template v-slot:body>
          Vous pouvez introduire une réclamation auprès d’une autorité de
          contrôle et exercer vos droits par courrier électronique à
          {{ APP_EMAIL }} ou par courrier postal à l’adresse suivante
          {{ APP_NAME }} – {{ APP_ADDRESS }}
          accompagné d’une copie d’un titre d’identité.
        </template>
      </CardWithLineGeneric>

      <p class="px-5 pb-5">Dernière mise à jour 24 mai 2024</p>
    </div>
  </div>
</template>

<script>
import { APP_NAME, APP_EMAIL, APP_ADDRESS } from "../../statics/app_static";
import CardWithLineGeneric from "../generics/Cards/CardWithLine/CardWithLineGeneric.vue";

import SimpleSectionHeader from "../generics/Headers/SimpleSectionHeader/SimpleSectionHeader.vue";
export default {
  name: "PolitiquesRgpd",
  components: {
    SimpleSectionHeader,
    CardWithLineGeneric,
  },
  data() {
    return {
      APP_NAME,
      APP_EMAIL,
      APP_ADDRESS,
    };
  },
};
</script>

<style>
.section-politiques ul {
  list-style: circle;
  margin: inherit;
  padding: 10px 20px;
}
.section-politiques p {
  padding-top: 10px;
}
</style>
