<template>
  <div class="repair-about-area ptb-80">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <div class="repair-about-content">
            <span class="sub-title">About Us</span>
            <h2>We have 35 years of experience in repair services</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p>

            <ul>
              <li>
                <span
                  ><vue-feather type="check"></vue-feather> Experienced
                  Professionals</span
                >
              </li>
              <li>
                <span
                  ><vue-feather type="check"></vue-feather> Expert Technical
                  Skills</span
                >
              </li>
              <li>
                <span
                  ><vue-feather type="check"></vue-feather> Excellent
                  Reputation</span
                >
              </li>
              <li>
                <span
                  ><vue-feather type="check"></vue-feather> Friendly
                  Service</span
                >
              </li>
              <li>
                <span
                  ><vue-feather type="check"></vue-feather> Trustworthy See
                  Reviews</span
                >
              </li>
              <li>
                <span
                  ><vue-feather type="check"></vue-feather> Affordable
                  Diagnosis</span
                >
              </li>
            </ul>
          </div>
        </div>

        <div class="col-lg-6">
          <div class="repair-about-image">
            <img src="../../../assets/img/about1.jpg" alt="image" />
            <img src="../../../assets/img/about2.jpg" alt="image" />
            <img src="../../../assets/img/about3.jpg" alt="image" />
            <img src="../../../assets/img/about-shape1.png" alt="image" />
            <img src="../../../assets/img/about-shape2.png" alt="image" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutUs",
};
</script>