<template>
  <div class="creative-area">
    <div class="d-table">
      <div class="d-table-cell">
        <div class="container">
          <div class="creative-inner-area">
            <div class="row">
              <div class="col-lg-6 col-sm-6 col-md-6">
                <div class="single-counter">
                  <h3>157</h3>
                  <p>Completed Projects</p>
                </div>
              </div>

              <div class="col-lg-6 col-sm-6 col-md-6">
                <div class="single-counter">
                  <h3>75</h3>
                  <p>Happy Clients</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CreativeInner",
};
</script>