<template>
    <div>
        <!-- Start Page Title -->
        <div class="page-title-area">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <h2>Blog Grid</h2>
                    </div>
                </div>
			</div>
			
			<div class="shape1"><img src="../../../assets/img/shape1.png" alt="shape"></div>
			<div class="shape2 rotateme"><img src="../../../assets/img/shape2.svg" alt="shape"></div>
			<div class="shape3"><img src="../../../assets/img/shape3.svg" alt="shape"></div>
			<div class="shape4"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
			<div class="shape5"><img src="../../../assets/img/shape5.png" alt="shape"></div>
			<div class="shape6 rotateme"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
			<div class="shape7"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
			<div class="shape8 rotateme"><img src="../../../assets/img/shape2.svg" alt="shape"></div>
        </div>
        <!-- End Page Title -->

        <!-- Start Blog Area -->
		<section class="blog-area ptb-80">
			<div class="container">
				<div class="row">
					<div class="col-lg-4 col-md-6">
                        <div class="single-blog-post-box">
                            <div class="entry-thumbnail">
                                <a href="#"><img src="../../../assets/img/blog-image/9.jpg" alt="image"></a>
                            </div>

                            <div class="entry-post-content">
                                <div class="entry-meta">
                                    <ul>
                                        <li><a href="#">Admin</a></li>
                                        <li>August 15, 2019</li>
                                    </ul>
                                </div>

                                <h3><a href="#">Making Peace With The Feast Or Famine Of Freelancing</a></h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod...</p>
                                <a href="#" class="learn-more-btn">Read Story <vue-feather type="plus"></vue-feather></a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6">
                        <div class="single-blog-post-box">
                            <div class="entry-thumbnail">
                                <a href="#"><img src="../../../assets/img/blog-image/10.jpg" alt="image"></a>
                            </div>

                            <div class="entry-post-content">
                                <div class="entry-meta">
                                    <ul>
                                        <li><a href="#">Admin</a></li>
                                        <li>August 18, 2019</li>
                                    </ul>
                                </div>

                                <h3><a href="#">I Used The Web For A Day On A 50 MB Budget</a></h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod...</p>
                                <a href="#" class="learn-more-btn">Read Story <vue-feather type="plus"></vue-feather></a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6">
                        <div class="single-blog-post-box">
                            <div class="entry-thumbnail">
                                <a href="#"><img src="../../../assets/img/blog-image/11.jpg" alt="image"></a>
                            </div>

                            <div class="entry-post-content">
                                <div class="entry-meta">
                                    <ul>
                                        <li><a href="#">Admin</a></li>
                                        <li>August 15, 2019</li>
                                    </ul>
                                </div>

                                <h3><a href="#">Here are the 5 most telling signs of micromanagement</a></h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod...</p>
                                <a href="#" class="learn-more-btn">Read Story <vue-feather type="plus"></vue-feather></a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6">
                        <div class="single-blog-post-box">
                            <div class="entry-thumbnail">
                                <a href="#"><img src="../../../assets/img/blog-image/12.jpg" alt="image"></a>
                            </div>

                            <div class="entry-post-content">
                                <div class="entry-meta">
                                    <ul>
                                        <li><a href="#">Admin</a></li>
                                        <li>August 15, 2019</li>
                                    </ul>
                                </div>

                                <h3><a href="#">The security risks of changing package owners</a></h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod...</p>
                                <a href="#" class="learn-more-btn">Read Story <vue-feather type="plus"></vue-feather></a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6">
                        <div class="single-blog-post-box">
                            <div class="entry-thumbnail">
                                <a href="#"><img src="../../../assets/img/blog-image/13.jpg" alt="image"></a>
                            </div>

                            <div class="entry-post-content">
                                <div class="entry-meta">
                                    <ul>
                                        <li><a href="#">Admin</a></li>
                                        <li>August 18, 2019</li>
                                    </ul>
                                </div>

                                <h3><a href="#">Tips to Protecting Your Business and Family</a></h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod...</p>
                                <a href="#" class="learn-more-btn">Read Story <vue-feather type="plus"></vue-feather></a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6">
                        <div class="single-blog-post-box">
                            <div class="entry-thumbnail">
                                <a href="#"><img src="../../../assets/img/blog-image/14.jpg" alt="image"></a>
                            </div>

                            <div class="entry-post-content">
                                <div class="entry-meta">
                                    <ul>
                                        <li><a href="#">Admin</a></li>
                                        <li>August 15, 2019</li>
                                    </ul>
                                </div>

                                <h3><a href="#">Protect Your Workplace from Cyber Attacks</a></h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod...</p>
                                <a href="#" class="learn-more-btn">Read Story <vue-feather type="plus"></vue-feather></a>
                            </div>
                        </div>
                    </div>
					
					<div class="col-lg-12 col-md-12">
						<div class="pagination-area">
							<nav aria-label="Page navigation">
								<ul class="pagination justify-content-center">
									<li class="page-item"><a class="page-link" href="#">Prev</a></li>
									<li class="page-item active"><a class="page-link" href="#">1</a></li>
									<li class="page-item"><a class="page-link" href="#">2</a></li>
									<li class="page-item"><a class="page-link" href="#">3</a></li>
									<li class="page-item"><a class="page-link" href="#">Next</a></li>
								</ul>
							</nav>
						</div>
					</div>
				</div>
			</div>
		</section>
		<!-- End Blog Area -->
    </div>
</template>

<script>
export default {
    name: 'BlogGridTwo'
}
</script>