<template>
  <div class="features-area ptb-80 bg-f7fafd">
    <div class="container">
      <div class="section-title">
        <h2>Our Features</h2>
        <div class="bar"></div>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </p>
      </div>

      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-6">
          <div class="single-features">
            <div class="icon">
              <vue-feather type="settings"></vue-feather>
            </div>

            <h3>Incredible Infrastructure</h3>
            <p>
              Lorem ipsum dolor amet, adipiscing, sed do eiusmod tempor
              incididunt ut labore dolore magna aliqua.
            </p>
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-6">
          <div class="single-features">
            <div class="icon">
              <vue-feather type="mail"></vue-feather>
            </div>

            <h3>Email Notifications</h3>
            <p>
              Lorem ipsum dolor amet, adipiscing, sed do eiusmod tempor
              incididunt ut labore dolore magna aliqua.
            </p>
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-6">
          <div class="single-features">
            <div class="icon bg-c679e3">
              <vue-feather type="grid"></vue-feather>
            </div>

            <h3>Simple Dashboard</h3>
            <p>
              Lorem ipsum dolor amet, adipiscing, sed do eiusmod tempor
              incididunt ut labore dolore magna aliqua.
            </p>
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-6">
          <div class="single-features">
            <div class="icon bg-c679e3">
              <vue-feather type="info"></vue-feather>
            </div>

            <h3>Information Retrieval</h3>
            <p>
              Lorem ipsum dolor amet, adipiscing, sed do eiusmod tempor
              incididunt ut labore dolore magna aliqua.
            </p>
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-6">
          <div class="single-features">
            <div class="icon bg-eb6b3d">
              <vue-feather type="mouse-pointer"></vue-feather>
            </div>

            <h3>Drag and Drop Functionality</h3>
            <p>
              Lorem ipsum dolor amet, adipiscing, sed do eiusmod tempor
              incididunt ut labore dolore magna aliqua.
            </p>
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-6">
          <div class="single-features">
            <div class="icon bg-eb6b3d">
              <vue-feather type="bell"></vue-feather>
            </div>

            <h3>Deadline Reminders</h3>
            <p>
              Lorem ipsum dolor amet, adipiscing, sed do eiusmod tempor
              incididunt ut labore dolore magna aliqua.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OurFeatures",
};
</script>