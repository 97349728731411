<template>
  <footer class="footer-area bg-f7fafd">
    <div class="container">
      <div class="row">
        <div class="col-lg-3 col-md-6 col-sm-6">
          <div class="single-footer-widget">
            <div class="logo">
              <router-link class="justify-content-center d-flex" to="/">
                <img
                  src="../../assets/img/logo-wdata-transparent.png"
                  width="150"
                  alt="logo"
                />
              </router-link>
            </div>
          </div>
        </div>

        <div class="col-lg-3 col-md-6 col-sm-6">
          <div class="single-footer-widget pl-5">
            <h3>Société</h3>
            <ul class="list">
              <li><router-link to="/">Accueil</router-link></li>
              <li><router-link to="/nos-offres">Carrières</router-link></li>
              <li>
                <router-link to="/engagement-rse">Engagements RSE</router-link>
              </li>
            </ul>
          </div>
        </div>

        <div class="col-lg-3 col-md-6 col-sm-6">
          <div class="single-footer-widget">
            <h3>Support</h3>
            <ul class="list">
              <li>
                <router-link to="/politiques-confidentialite"
                  >Politique de confidentialité</router-link
                >
              </li>
              <li>
                <router-link to="/mentions-legales"
                  >Mentions légales</router-link
                >
              </li>
              <li><router-link to="/contact">Contactez-nous</router-link></li>
            </ul>
          </div>
        </div>

        <div class="col-lg-3 col-md-6 col-sm-6">
          <div class="single-footer-widget">
            <h3>Adresse</h3>

            <ul class="footer-contact-info">
              <li>
                <vue-feather type="map-pin"></vue-feather> {{ APP_ADDRESS }}
              </li>
              <li>
                <vue-feather type="mail"></vue-feather>
                <a :href="'mailto:' + APP_EMAIL">{{ APP_EMAIL }}</a>
              </li>
            </ul>
            <ul class="social-links">
              <li>
                <a
                  href="https://www.linkedin.com/company/worldata-team/mycompany/"
                  target="_blank"
                  class="linkedin"
                >
                  <vue-feather type="linkedin"></vue-feather>
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div class="col-lg-12 col-md-12">
          <div class="copyright-area">
            <p>
              Copyright &copy; {{ currentYear }} {{ APP_NAME }}. Tous droits
              réservés.
            </p>
          </div>
        </div>
      </div>
    </div>

    <img src="../../assets/img/map.png" class="map" alt="map" />
    <div class="shape1">
      <img src="../../assets/img/shape1.png" alt="shape" />
    </div>
    <div class="shape8 rotateme">
      <img src="../../assets/img/shape2.svg" alt="shape" />
    </div>

    <div
      @click="scrollToTop()"
      :class="['back-to-top-btn', { 'go-top': isTop }]"
    >
      <vue-feather type="arrow-up"></vue-feather>
    </div>
  </footer>
</template>

<script>
import {
  APP_ADDRESS,
  APP_EMAIL,
  APP_NAME,
  APP_PHONE,
} from "../../statics/app_static";

export default {
  name: "MainFooter",
  data() {
    return {
      isTop: false,
      currentYear: new Date().getFullYear(),
      APP_EMAIL,
      APP_NAME,
      APP_ADDRESS,
      APP_PHONE,
    };
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
  mounted() {
    const that = this;
    window.addEventListener("scroll", () => {
      let scrollPos = window.scrollY;
      if (scrollPos >= 100) {
        that.isTop = true;
      } else {
        that.isTop = false;
      }
    });
  },
};
</script>
