<template>
  <nav aria-label="Page navigation">
    <ul class="pagination">
      <li class="page-item">
        <button class="page-link" @click="previousAction()">Précédent</button>
      </li>
      <li class="page-item" v-for="i in maxPage" :key="i">
        <button
          class="page-link"
          type="button"
          :class="{ active: active_page == i }"
          @click="switchPage(i)"
        >
          {{ i }}
        </button>
      </li>
      <li class="page-item">
        <button class="page-link" @click="nextAction()" type="button">
          Suivant
        </button>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: "PaginationComponent",
  data() {
    return {
      active_page: 1,
    };
  },
  props: {
    maxPage: Number,
    currentPage: Number,
  },
  mounted() {
    this.active_page = this.currentPage;
  },
  methods: {
    switchPage(page) {
      if (page > this.maxPage || page < 1) {
        return;
      }
      this.active_page = page;
      window.scrollTo(0, 0);
      this.$emit("switch_page", page);
    },
    nextAction() {
      this.switchPage(this.active_page + 1);
    },
    previousAction() {
      this.switchPage(this.active_page - 1);
    },
  },
};
</script>
