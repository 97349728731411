<template>
  <div class="hosting-main-banner">
    <div class="d-table">
      <div class="d-table-cell">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
              <div class="hosting-banner-content">
                <h1>The Best Web Hosting</h1>
                <ul>
                  <li>FREE Domain Name for 1st Year</li>
                  <li>FREE SSL Certificate Included</li>
                  <li>1-Click Wordpress Install</li>
                  <li>24/7 Support</li>
                </ul>
                <p>Starting at <span>$8.99</span> $3.95/month</p>
                <a href="#" class="btn btn-primary">Get Started</a>
              </div>
            </div>

            <div class="col-lg-6 col-md-12">
              <div class="hosting-banner-image">
                <img
                  src="../../../assets/img/hosting-banner-image/1.png"
                  alt="image"
                />
                <img
                  src="../../../assets/img/hosting-banner-image/2.png"
                  alt="image"
                />
                <img
                  src="../../../assets/img/hosting-banner-image/3.png"
                  alt="image"
                />
                <img
                  src="../../../assets/img/hosting-banner-image/4.png"
                  alt="image"
                />
                <img
                  src="../../../assets/img/hosting-banner-image/5.png"
                  alt="image"
                />
                <img
                  src="../../../assets/img/hosting-banner-image/6.png"
                  alt="image"
                />
                <img
                  src="../../../assets/img/hosting-banner-image/7.png"
                  alt="image"
                />
                <img
                  src="../../../assets/img/hosting-banner-image/8.png"
                  alt="image"
                />
                <img
                  src="../../../assets/img/hosting-banner-image/9.png"
                  alt="image"
                />
                <img
                  src="../../../assets/img/hosting-banner-image/static-main.png"
                  alt="image"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="shape1">
      <img src="../../../assets/img/shape1.png" alt="shape" />
    </div>
    <div class="shape2 rotateme">
      <img src="../../../assets/img/shape2.svg" alt="shape" />
    </div>
    <div class="shape3">
      <img src="../../../assets/img/shape3.svg" alt="shape" />
    </div>
    <div class="shape4">
      <img src="../../../assets/img/shape4.svg" alt="shape" />
    </div>
    <div class="shape5">
      <img src="../../../assets/img/shape5.png" alt="shape" />
    </div>
    <div class="shape6 rotateme">
      <img src="../../../assets/img/shape4.svg" alt="shape" />
    </div>
    <div class="shape7">
      <img src="../../../assets/img/shape4.svg" alt="shape" />
    </div>
    <div class="shape8 rotateme">
      <img src="../../../assets/img/shape2.svg" alt="shape" />
    </div>
  </div>
</template>

<script>
export default {
  name: "MainBanner",
};
</script>