<template>
  <li class="nav-item">
    <router-link
      :to="menuElt.link"
      class="nav-link"
      @click="$emit('hide-menu', 1)"
      >{{ menuElt.name }}</router-link
    >
  </li>
</template>
<script>
export default {
  name: "MenuHeaderSingleBtn",
  props: {
    menuElt: {
      type: Object,
      required: true,
    },
  },
};
</script>
