<template>
  <!-- Start Preloader Area -->
  <div class="preloader bg-white">
    <div class="h-100 d-flex justify-content-center align-items-center">
      <img
        src="../../assets/img/logo-wdata-transparent.png"
        width="400"
        alt="logo"
        class="logo spinner shadow-none"
      />
    </div>
  </div>
  <!-- End Preloader Area -->
</template>

<style>
@keyframes fadeInOut {
  0% {
    opacity: 0.5;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.5;
  }
}

.logo {
  animation: fadeInOut 2s ease-in-out infinite;
}
</style>
<script>
export default {
  name: "PreLoader",
};
</script>
