<template>
  <div class="why-choose-us ptb-80 pt-0">
    <div class="container">
      <div class="row py-5">
        <div class="col-lg-6 col-md-12">
          <div class="d-flex flex-column">
            <div class="section-title tw-mb-12.5">
              <h2 class="pb-3">
                <well-formatted-title blackArea="Politique RSE" />
              </h2>
              <p class="tw-max-w-full">
                En tant qu'ESN engagée, WORLDATA TEAM reconnaît l'importance de
                jouer un rôle actif dans la construction d'un avenir meilleur
                pour tous.
              </p>
              <p class="tw-max-w-full">
                Nous nous efforçons de placer la RSE au cœur de notre stratégie
                d'entreprise et de faire de notre mieux pour avoir un impact
                positif sur la société et l'environnement.
              </p>
              <p class="pt-4 tw-max-w-full">
                {{ APP_NAME }} s’unit autour d’une politique RSE fédératrice et
                implique ses collaborateurs autour de projets utiles et
                valorisants.
              </p>
            </div>

            <div class="why-choose-us-image">
              <div
                class="tw-h-full tw-w-full d-none d-lg-flex tw-relative tw-justify-center"
              >
                <img
                  src="../../../../assets/img/services-left-image/cercle-shape.png"
                  class="rotateme tw-left-0"
                  alt="image"
                />
              </div>
              <div
                class="lg:tw-absolute tw-w-full tw-h-full tw-top-0 tw-left-0 tw-flex tw-justify-center"
              >
                <div class="my-auto">
                  <img
                    src="../../../../assets/img/engagements-rse/5-rse.png"
                    alt="image"
                    class="tw-left-0 tw-w-[400px]"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-6 col-md-12">
          <div class="row">
            <div
              class="col-lg-6 col-md-6 col-sm-6 mb-3"
              v-for="section in sections"
              :key="section.id"
            >
              <div class="single-why-choose-us m-0 h-100">
                <div class="icon">
                  <i :class="section.icon"></i>
                </div>
                <h3>{{ section.title }}</h3>
                <p>{{ section.description }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { APP_NAME } from "../../../../statics/app_static";
import WellFormattedTitle from "../../../generics/Typography/Divider/WellFormattedTitle.vue";
export default {
  components: { WellFormattedTitle },
  name: "EngagementRsePolitique",
  data() {
    return {
      APP_NAME,
      sections: [
        {
          id: 1,
          title: "La responsabilité Sociale",
          icon: "flaticon-team",
          description:
            "Respecter les droits des individus et promouvoir l'équité sociale à travers nos actions.",
        },

        {
          id: 2,
          title: "La responsabilité Organisationnelle",
          icon: "flaticon-rocket",
          description:
            "Intégrer toutes les parties prenantes à notre stratégie",
        },
        {
          id: 3,
          title: "La responsabilité Sociétale",
          icon: "flaticon-shield",
          description:
            "Créer de la valeur à long terme pour la société dans son ensemble en œuvrant pour un développement durable",
        },
        {
          id: 4,
          title: "La responsabilité environnementale",
          icon: "flaticon-diamond",
          description:
            "Intégration d'une dimension environnementale à tous les niveaux de nos activités",
        },
      ],
    };
  },
};
</script>
