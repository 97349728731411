<template>
    <div class="page-title-area">
        <div class="contact-area ptb-80 pt-0">
            <div class="container">
                <div class="section-title">
                    <h2>Se connecter</h2>
                    <div class="bar"></div>
                </div>

                <div class="row h-100 justify-content-center align-items-center">

                    <div class="col-lg-6 col-md-12">
                        <form id="contactForm" class="card p-4 border-0 shadow">
                            <div class="row">
                                <div class="col-lg-12 col-md-12 text-start">
                                    <div class="form-group">
                                        <label>Login</label>
                                        <input type="text" name="name" id="name" class="form-control" required
                                            data-error="Please enter your name" placeholder="Votre identifiant" />
                                        <div class="help-block with-errors"></div>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12 text-start">
                                    <div class="form-group">
                                        <label>Mot de passe</label>
                                        <input type="password" name="email" class="form-control" required
                                            data-error="Please enter your email" placeholder="Votre mot de passe" />
                                        <div class="help-block with-errors"></div>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12">
                                    <button type="submit" class="btn btn-primary">
                                        Valider
                                    </button>
                                    <div id="msgSubmit" class="h3 text-center hidden"></div>
                                    <div class="clearfix"></div>
                                    <div class="d-flex justify-content-center py-3 mx-5">
                                        <div class="border-bottom w-100"></div>
                                    </div>
                                    <a href="http://">Vous n'avez pas un compte ? Devenir membre</a>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: 'LoginPage'
}
</script>

<style>
.login-page {
    padding-top: 80px;
}

.banner-form {
    background: #ffffff;
    -webkit-box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
    padding: 50px 30px;
    border-radius: 5px;
}

.w-10 {
    width: 10rem;
}
</style>