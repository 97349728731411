<template>
  <!-- Start Boxes Area -->

  <div class="row">
    <div
      class="col-lg-3 text-center p-0 odd:tw-bg-orange-500 even:tw-bg-wdata-blue position-relative"
      v-for="box in boxes"
      :key="box.id"
    >
      <div class="tw-px-6.5 tw-py-24.5">
        <div
          class="tw-absolute tw-left-0 tw-w-full tw-h-full tw-font-bold tw-opacity-10 d-flex text-center tw-top-0 tw-text-xxl align-items-center justify-content-center"
        >
          <Counter :targetVal="box.label" class="text-white" />
        </div>
        <p class="tw-text-5xl text-white tw-z-1 tw-font-semibold">
          <Counter :targetVal="box.label" />{{ box.unit }}
        </p>
        <p
          class="text-white tw-text-xl text-uppercase tw-font-semibold tw-tracking-widest"
        >
          {{ box.description }}
        </p>
      </div>
    </div>
  </div>

  <!-- End Boxes Area -->
</template>

<script>
import Counter from "../../generics/Counter/Counter.vue";
export default {
  components: { Counter },
  name: "BoxesArea",
  data() {
    return {
      boxes: [
        {
          id: 1,
          label: 39,
          unit: "%",
          description: "banque & assurance",
          color: "",
          icon: "server",
        },
        {
          id: 2,
          label: 29,
          unit: "%",
          description: "retail",
          color: "bg-f78acb",
          icon: "code",
        },
        {
          id: 3,
          label: 19,
          unit: "%",
          description: "énergie",
          color: "bg-eb6b3d",
          icon: "git-branch",
        },
        {
          id: 4,
          label: 13,
          unit: "%",
          description: "telecom & média",
          color: "bg-c679e3",
          icon: "users",
        },
      ],
    };
  },
};
</script>
