<template>
  <section
    class="container-fluid lg:tw-min-h-187.5 tw-min-h-75 tw-duration-500 tw-py-35 tw-overflow-hidden tw-relative d-flex align-items-center bg-gradient-blue"
  >
    <video
      class="tw-absolute tw-left-0 tw-top-0 tw-w-full tw-h-full tw-object-cover -tw-z-[1] tw-bg-gradient-to-r tw-from-orange-500 tw-to-orange-700"
      loop=""
      autoplay=""
      muted=""
      src="https://didousoft.tn/etab/wdata/uploads/teaser3.mp4"
    ></video>

    <div class="lg:tw-pl-10 tw-pl-1">
      <div>
        <h2 class="lg:tw-text-4xl tw-text-xl tw-font-bold">
          <div class="tw-text-white">{{ APP_NAME }}</div>
          <div>Votre partenaire de confiance.</div>
        </h2>
        <div
          class="tw-my-3 tw-h-0.5 tw-bg-[#19212e] tw-border-opacity-50 tw-w-32"
        ></div>
      </div>
    </div>
  </section>
</template>

<script>
import { APP_NAME } from "../../../statics/app_static";
export default {
  name: "MainBanner",
  data() {
    return {
      APP_NAME,
    };
  },
};
</script>
