<template>
  <div class="pt-5">
    <SimpleSectionHeader title="NOS OFFRES D'EMPLOI" />

    <div>
      <div class="container pb-4">
        <span class="tw-animate-ping" v-if="isLoading">
          Chargement en cours...
        </span>
        <div class="row">
          <div class="col-md-12 mb-4" v-for="(job, i) in jobs" :key="i">
            <CardWithLine :title="job.title.label" :id="job.id" />
          </div>
        </div>

        <div class="d-flex justify-content-center my-5">
          <PaginationComponent
            :maxPage="maxPage"
            @switch_page="getJobs"
            :currentPage="currentPage"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PaginationComponent from "../generics/Buttons/Pagination/PaginationComponent.vue";
import SimpleSectionHeader from "../generics/Headers/SimpleSectionHeader/SimpleSectionHeader.vue";
import CardWithLine from "../generics/Cards/CardWithLine/CardWithLine.vue";
import { BACK_URL } from "../../statics/app_static";

export default {
  name: "NosOffresPage",
  components: {
    PaginationComponent,
    SimpleSectionHeader,
    CardWithLine,
  },
  data() {
    return {
      jobs: [],
      isLoading: true,
      maxPage: 0,
      minPage: 0,
      currentPage: 1,
    };
  },
  mounted() {
    this.getJobs();
  },
  methods: {
    getJobs(page = 1) {
      this.isLoading = true;
      fetch(BACK_URL + "/api/jobs?page=" + page)
        .then((res) => res.json())
        .then((res) => {
          this.jobs = res.data;
          this.isLoading = false;
          this.maxPage = res.last_page;
          this.currentPage = res.current_page;
        });
    },
  },
};
</script>
