<template>
  <div class="d-flex gap-3 justify-content-center mt-4">
    <div class="d-block" v-if="writer">
      <span class="text-warning">
        <vue-feather type="edit" class="desc-icon"></vue-feather>
      </span>
      {{ writer }}
    </div>
    <div class="d-block" v-if="date">
      <span class="text-warning">
        <vue-feather type="calendar" class="desc-icon"></vue-feather>
      </span>
      {{ date }}
    </div>
    <!-- <div class="d-block">
      <span class="text-warning" v-if="location">
        <vue-feather type="map-pin" class="desc-icon"></vue-feather>
      </span>
      {{ location }}
    </div> -->
  </div>
</template>

<script>
export default {
  name: "SimpleSectionHeaderLocs",
  props: {
    date: String,
    location: String,
    writer: String,
  },
};
</script>
