<template>
    <div class="ml-services-area ptb-80">
        <div class="container">
            <div class="section-title">
                <span class="sub-title">Our Solutions</span>
                <h2>Our Services</h2>
                <div class="bar"></div>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
            </div>

            <div class="row">
                <div class="col-lg-4 col-sm-6 col-md-6">
                    <div class="single-ml-services-box">
                        <div class="image">
                            <img src="../../../assets/img/services-image/1.png" alt="image">
                        </div>
                        <h3><a href="#">IT Professionals</a></h3>
                        <p>Lorem ipsum dolor sit amet elit, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6 col-md-6">
                    <div class="single-ml-services-box">
                        <div class="image">
                            <img src="../../../assets/img/services-image/2.png" alt="image">
                        </div>
                        <h3><a href="#">Software Engineers</a></h3>
                        <p>Lorem ipsum dolor sit amet elit, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6 col-md-6">
                    <div class="single-ml-services-box">
                        <div class="image">
                            <img src="../../../assets/img/services-image/3.png" alt="image">
                        </div>
                        <h3><a href="#">Health & Manufacturing</a></h3>
                        <p>Lorem ipsum dolor sit amet elit, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6 col-md-6">
                    <div class="single-ml-services-box">
                        <div class="image">
                            <img src="../../../assets/img/services-image/4.png" alt="image">
                        </div>
                        <h3><a href="#">Security & Surveillance</a></h3>
                        <p>Lorem ipsum dolor sit amet elit, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6 col-md-6">
                    <div class="single-ml-services-box">
                        <div class="image">
                            <img src="../../../assets/img/services-image/5.png" alt="image">
                        </div>
                        <h3><a href="#">Automatic Optimization</a></h3>
                        <p>Lorem ipsum dolor sit amet elit, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6 col-md-6">
                    <div class="single-ml-services-box">
                        <div class="image">
                            <img src="../../../assets/img/services-image/6.png" alt="image">
                        </div>
                        <h3><a href="#">Data Analysts</a></h3>
                        <p>Lorem ipsum dolor sit amet elit, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="shape1"><img src="../../../assets/img/shape1.png" alt="shape"></div>
        <div class="shape2 rotateme"><img src="../../../assets/img/shape2.svg" alt="shape"></div>
        <div class="shape3"><img src="../../../assets/img/shape3.svg" alt="shape"></div>
        <div class="shape4"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
        <div class="shape6 rotateme"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
        <div class="shape7"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
        <div class="shape8 rotateme"><img src="../../../assets/img/shape2.svg" alt="shape"></div>
    </div>
</template>

<script>
export default {
    name: 'OurServices'
}
</script>