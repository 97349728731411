<template>
  <div class="tw-pt-10 overflow-hidden">
    <MainBanner></MainBanner>

    <!-- Notre coeur de métier -->
    <!-- Start Ready To Talk Area -->
    <div class="tw-bg-[#081c37] tw-py-30">
      <div class="container">
        <div class="tw-text-white tw-text-xl tw-font-light text-center">
          WORLDATA TEAM s'engage à vous apporter un accompagnement agile et
          personnalisé en mettant à votre disposition les compétences adaptées à
          chacun de vos projets.
        </div>
      </div>
    </div>
    <!-- End Ready To Talk Area -->

    <!-- Domaines d'expertise -->
    <AvenirArea></AvenirArea>

    <!-- Stats -->
    <StatsArea></StatsArea>

    <!-- Feuille de route -->
    <!--FeuilleDeRoute></-FeuilleDeRoute-->

    <DomaineExpertise />
    <!--ServicesArea></!--ServicesArea-->
    <PolitiqueRse></PolitiqueRse>
    <!--     <OurTeam></OurTeam> -->
    <nos-dernieres-offres></nos-dernieres-offres>

    <!--OurBlog></!--OurBlog-->
    <nos-expertises-solutions />
  </div>
</template>

<script>
import MainBanner from "./accueil-components/MainBannerVideo.vue";
import StatsArea from "./accueil-components/StatsArea";
import AvenirArea from "./accueil-components/AvenirArea";
//import ServicesArea from "./accueil-components/ServicesArea";
import PolitiqueRse from "./accueil-components/PolitiqueRse.vue";
// import OurTeam from "./accueil-components/OurTeam";
//import FeuilleDeRoute from "./accueil-components/FeuilleDeRoute";
//import OurBlog from "./accueil-components/OurBlog";
import DomaineExpertise from "./accueil-components/DomaineExpertise.vue";

import NosDernieresOffres from "./accueil-components/NosDernieresOffres.vue";

import NosExpertisesSolutions from "./accueil-components/NosExpertisesSolutions/NosExpertisesSolutions.vue";
export default {
  name: "AccueilPage",
  components: {
    MainBanner,
    StatsArea,
    NosDernieresOffres,

    AvenirArea,
    DomaineExpertise,
    //ServicesArea,
    PolitiqueRse,
    //OurTeam,
    //FeuilleDeRoute,
    //OurBlog,
    NosExpertisesSolutions,
  },
};
</script>
