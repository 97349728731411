<template>
  <SimpleSectionHeader
    customClass="feedback-area ptb-80 bg-f7fafd"
    :title="!hasPostulee() ? 'Postuler' : null"
    isSectionTitle
  >
    <div class="container">
      <CandidatureDeposee v-if="hasPostulee()" />
      <form
        id="contactForm"
        @submit="triggerSubmit"
        class="p-4 border-0"
        v-else
      >
        <div class="row">
          <div class="col-md-6 text-start">
            <InputWithLabel name="nom" label="Nom" />
          </div>

          <div class="col-md-6 text-start">
            <InputWithLabel name="prenom" label="Prénom" />
          </div>

          <div class="col-md-12 text-start">
            <InputWithLabel name="email" label="Email" />
          </div>

          <div class="col-md-6 text-start">
            <InputWithLabel name="telephone" label="Téléphone" />
          </div>

          <div class="col-md-6 text-start">
            <label>Localisation</label>
            <select name="location" class="form-control">
              <option
                v-for="location in locations"
                :value="location.code"
                :key="location.code"
              >
                {{ location.name }}
              </option>
            </select>
          </div>

          <div class="col-md-12 text-start">
            <InputWithLabel name="object" label="Objet" />
            <InputWithLabel
              name="cv"
              label="Télécharger votre CV"
              type="file"
              accept=".pdf"
            />
            <InputWithLabel
              label="A propos de vous"
              placeholder="Votre description ici"
              name="description"
              type="textarea"
            />
          </div>

          <div class="col-md-12 col-md-12">
            <div class="ready-to-talk p-0 m-0 d-inline-block bg-none me-3">
              <button type="reset" class="btn btn-primary">Annuler</button>
            </div>
            <button type="submit" class="btn btn-primary">Postuler</button>
            <div id="msgSubmit" class="h3 text-center hidden"></div>
            <div class="clearfix"></div>
          </div>
        </div>
      </form>
    </div>
  </SimpleSectionHeader>
</template>

<script setup>
import { BACK_URL } from "../../../statics/app_static";
import SimpleSectionHeader from "../../generics/Headers/SimpleSectionHeader/SimpleSectionHeader.vue";
import InputWithLabel from "../../generics/Inputs/InputWithLabel/InputWithLabel.vue";
import { locations } from "./OffrePostulerSection.services";
import CandidatureDeposee from "./CandidatureDeposee.vue";
import { useRoute, useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.js";

const $route = useRoute();
const $router = useRouter();

const hasPostulee = () => {
  const listPosts = localStorage.getItem("listPosts");
  if (!listPosts) {
    return false;
  }
  return listPosts.split(",").includes($route.params.id);
};

const savePostulee = () => {
  const listPosts = localStorage.getItem("listPosts");
  if (!listPosts) {
    localStorage.setItem("listPosts", $route.params.id);
  } else {
    localStorage.setItem("listPosts", listPosts + "," + $route.params.id);
  }
};

const triggerSubmit = (e) => {
  e.preventDefault();
  if (hasPostulee()) {
    Swal.fire({
      title: "Erreur !",
      text: "Vous avez déjà postulé à cette offre",
      icon: "error",
    });
    return false;
  }
  var form = document.getElementById("contactForm");
  var formData = new FormData(form);
  if (formData.get("object").length < 5) {
    Swal.fire({
      title: "Erreur !",
      text: "L'objet doit contenir au mois 5 caractères",
      icon: "error",
    });
    return false;
  }
  if (formData.get("description").length < 10) {
    Swal.fire({
      title: "Erreur !",
      text: "La description doit contenir au mois 5 caractères",
      icon: "error",
    });

    return false;
  }

  fetch(BACK_URL + "/api/jobs/" + $route.params.id + "/candidats/postuler", {
    method: "post",
    body: formData,
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.status == true) {
        Swal.fire({
          title: "Succès !",
          text: "Merci pour votre candidature ! Notre équipe RH prendra bientôt le temps d'étudier votre profil.",
          icon: "success",
        });
        savePostulee();
        $router.push("/nos-offres");
      } else {
        Swal.fire({
          title: "Erreur !",
          text: "Impossible de postuler à cette offre. Vous avez déjà postulé avec le même email",
          icon: "error",
        });

        console.error(res.message);
      }
    })
    .catch((e) => {
      alert(e);
    });
};
</script>
