<template>
  <div class="tw-px-10">
    <div class="row tw-justify-center">
      <div
        class="col-lg-3 col-md-3 col-12"
        v-for="section in sections"
        :key="section.id"
      >
        <CardWithIcon class="p-2 tw-cursor-pointer h-100" :showDivider="false">
          <template v-slot:s-image>
            <div class="image tw-h-50 tw-flex tw-justify-center">
              <div class="tw-my-auto">
                <img :src="section.image" class="tw-max-h-45" alt="image" />
              </div>
            </div>
          </template>
          <!-- <template v-slot:s-title>
            {{ section.title }}
          </template>
          <template v-slot:s-body>
            {{ section.description }}
          </template> -->
        </CardWithIcon>
      </div>
    </div>
  </div>
</template>

<script>
import CardWithIcon from "../../../generics/Cards/CardWithIcon/CardWithIcon.vue";
export default {
  name: "EngagementRseDemarche",
  components: {
    CardWithIcon,
  },
  data() {
    return {
      sections: [
        {
          id: 1,
          image: require("../../../../assets/img/engagements-rse/3-ecovadis.png"),
          title: "La responsabilité Sociale",
          description:
            "Respecter les droits des individus et promouvoir l'équité sociale à travers nos actions.",
        },

        {
          id: 2,
          image: require("../../../../assets/img/engagements-rse/2-global-impact.png"),
          title: "La responsabilité Organisationnelle",
          description:
            "Intégrer toutes les parties prenantes à notre stratégie",
        },
        {
          id: 3,
          image: require("../../../../assets/img/engagements-rse/1-charte.png"),
          title: "La responsabilité Sociétale",
          description:
            "Créer de la valeur à long terme pour la Société dans son ensemble en œuvrant pour un développement durable",
        },
        {
          id: 4,
          image: require("../../../../assets/img/engagements-rse/4-planet-techcare.png"),
          title: "La responsabilité environnementale",
          description:
            "Intégration d'une dimension environnementale à tous les niveaux de nos activités",
        },
      ],
    };
  },
};
</script>
