<template>
  <div class="iot-services-area ptb-80">
    <div class="container">
      <div class="section-title">
        <h2>Our Featured Services that We Provide</h2>
        <div class="bar"></div>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </p>
      </div>

      <div class="row">
        <div class="col-lg-4 col-md-6 col-sm-6">
          <div class="single-iot-services">
            <div class="icon">
              <i class="flaticon-software"></i>
            </div>

            <h3>Software Development <br />IoT Solutions</h3>
            <p>
              Lorem ipsum dolor sit amet elit, adipiscing, sed do eiusmod tempor
              incididunt ut labore dolore magna aliqua.
            </p>

            <a href="#"><vue-feather type="arrow-right"></vue-feather></a>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 col-sm-6">
          <div class="single-iot-services">
            <div class="icon">
              <i class="flaticon-gear"></i>
            </div>

            <h3>Smart Home, Office <br />IoT Solutions</h3>
            <p>
              Lorem ipsum dolor sit amet elit, adipiscing, sed do eiusmod tempor
              incididunt ut labore dolore magna aliqua.
            </p>

            <a href="#"><vue-feather type="arrow-right"></vue-feather></a>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 col-sm-6">
          <div class="single-iot-services">
            <div class="icon">
              <i class="flaticon-skyline"></i>
            </div>

            <h3>Smart City <br />IoT Solutions</h3>
            <p>
              Lorem ipsum dolor sit amet elit, adipiscing, sed do eiusmod tempor
              incididunt ut labore dolore magna aliqua.
            </p>

            <a href="#"><vue-feather type="arrow-right"></vue-feather></a>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 col-sm-6">
          <div class="single-iot-services">
            <div class="icon">
              <i class="flaticon-car-insurance"></i>
            </div>

            <h3>Automative <br />IoT Solutions</h3>
            <p>
              Lorem ipsum dolor sit amet elit, adipiscing, sed do eiusmod tempor
              incididunt ut labore dolore magna aliqua.
            </p>

            <a href="#"><vue-feather type="arrow-right"></vue-feather></a>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 col-sm-6">
          <div class="single-iot-services">
            <div class="icon">
              <i class="flaticon-factory"></i>
            </div>

            <h3>Industrial <br />IoT Solutions</h3>
            <p>
              Lorem ipsum dolor sit amet elit, adipiscing, sed do eiusmod tempor
              incididunt ut labore dolore magna aliqua.
            </p>

            <a href="#"><vue-feather type="arrow-right"></vue-feather></a>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 col-sm-6">
          <div class="single-iot-services">
            <div class="icon">
              <i class="flaticon-chip"></i>
            </div>

            <h3>System on Chip <br />IoT Solutions</h3>
            <p>
              Lorem ipsum dolor sit amet elit, adipiscing, sed do eiusmod tempor
              incididunt ut labore dolore magna aliqua.
            </p>

            <a href="#"><vue-feather type="arrow-right"></vue-feather></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OurServices",
};
</script>