<template>
  <div class="repair-main-banner tw-pb-14">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6">
          <div class="repair-banner-content">
            <h1>
              <well-formatted-title blackArea="NOS EXPERTISES" />
            </h1>
            <p class="mb-2 tw-max-w-full">
              Nous accompagnons nos clients dans la transformation et
              l’optimisation de leurs infrastructures IT pour garantir
              performance, sécurité et continuité d’activité.
            </p>
            <p class="tw-max-w-full">
              Convaincus qu’une Infrastructure IT doit être à la fois agile et
              automatisée, nous vous accompagnons pour faire de l’innovation
              technologique un véritable levier de croissance et de réussite.
            </p>
            <p class="mb-2 tw-max-w-full">
              Notre savoir-faire couvre l’ensemble du cycle de vie de vos
              environnements IT, qu’il s’agisse d’Infrastructures On-Premise ou
              Cloud (public, privé, hybride).
            </p>
            <p class="mb-2 tw-max-w-full">
              Nous intervenons sur des projets de modernisation,
              d'automatisation ainsi que sur l'exploitation et l'optimisation de
              vos infrastructures de production.
            </p>
            <p class="tw-max-w-full">
              En combinant nos expertises autour des Infrastructures Legacy/
              Cloud et pratiques DevOps nous aidons nos clients à construire des
              environnements robustes, évolutifs et parfaitement alignés avec
              leurs enjeux stratégiques.
            </p>
          </div>
        </div>

        <div class="col-lg-6">
          <div class="repair-banner-image">
            <img
              src="../../../assets/img/hosting-banner-image/static-main.png"
              alt="image"
            />
            <img
              src="../../../assets/img/repair-banner-image/circle.png"
              class="rotateme"
              alt="image"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { APP_NAME } from "../../../statics/app_static";
import WellFormattedTitle from "../../generics/Typography/Divider/WellFormattedTitle.vue";
export default {
  components: { WellFormattedTitle },
  name: "NosExpertisesHeader",
  data() {
    return {
      APP_NAME,
    };
  },
};
</script>
