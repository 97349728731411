<template>
  <div class="single-hosting-features">
    <router-link :to="'/details-offre/' + id" class="d-flex gap-3">
      <div class="text-start flex-grow-1">
        <h3 class="mt-2 d-flex">
          <vue-feather type="chevron-right" />
          {{ title }}
        </h3>
        <p><strong>Type de contrat :</strong> CDI</p>
      </div>
      <div>
        <ButtonComponent> Voir l'offre </ButtonComponent>
      </div>
    </router-link>
  </div>
</template>

<script>
import ButtonComponent from "../../Buttons/ButtonComponent.vue";

export default {
  name: "CardWithLine",
  components: {
    ButtonComponent,
  },
  props: {
    title: String,
    id: Number,
  },
};
</script>
