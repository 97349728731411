<template>
  <div
    class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-bg-white tw-rounded-lg tw-p-6"
  >
    <h2 class="tw-text-2xl tw-font-bold tw-text-gray-800">
      Candidature déposée
    </h2>
    <p class="tw-text-gray-600 tw-mt-2 tw-text-center tw-pb-4">
      Merci pour votre candidature ! Notre équipe RH prendra bientôt le temps
      d'étudier votre profil.
    </p>
    <router-link to="/nos-offres" class="btn btn-primary"
      >Consulter la liste des offres</router-link
    >
  </div>
</template>
<script setup></script>
