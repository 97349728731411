<template>
  <div class="single-features">
    <slot name="s-image"></slot>

    <h2>
      <div class="section-title mb-0 pb-2 text-start text-uppercase">
        <slot name="s-title"></slot>
        <DividerVue class="mt-3" v-if="showDivider" />
      </div>
    </h2>
    <p>
      <slot name="s-body"></slot>
    </p>
  </div>
</template>

<script>
import DividerVue from "../../Typography/Divider/Divider.vue";
export default {
  name: "CardWithIcon",
  components: {
    DividerVue,
  },
  props: {
    icon: String,
    iconColor: String,
    showDivider: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      possibleIcons: ["settings", "mail"],
    };
  },
  methods: {
    getIcon() {
      return this.possibleIcons[this.icon] ?? this.possibleIcons["settings"];
    },
  },
};
</script>
