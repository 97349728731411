<template>
  <div class="pt-5">
    <SimpleSectionHeader
      class="tw-pt-[170px]"
      :wellFormatted="true"
      title="Engagement RSE"
    >
    </SimpleSectionHeader>

    <div class="container">
      <!-- Bloc 1 -->
      <EngagementRsePolitique />
      <!-- Bloc 2 -->
    </div>
    <div class="container">
      <div class="section-title mb-4">
        <h2 class="text-start mb-3 pt-5 tw-normal-case">
          Nos Engagements RSE : plus qu'un concept, une réalité.
        </h2>
      </div>
    </div>
    <EngagementRseDemarche />
  </div>
</template>

<script>
import {
  APP_ADDRESS,
  APP_EMAIL,
  APP_NAME,
  APP_PHONE,
} from "../../statics/app_static";
import SimpleSectionHeader from "../generics/Headers/SimpleSectionHeader/SimpleSectionHeader.vue";
import EngagementRseDemarche from "./accueil-components/EngagementRse/EngagementRseDemarche.vue";
import EngagementRsePolitique from "./accueil-components/EngagementRse/EngagementRsePolitique.vue";

export default {
  name: "EngagementRse",
  components: {
    SimpleSectionHeader,
    EngagementRsePolitique,
    EngagementRseDemarche,
  },
  data() {
    return {
      APP_NAME,
      APP_ADDRESS,
      APP_EMAIL,
      APP_PHONE,
    };
  },
};
</script>
