<template>
  <li class="nav-item">
    <a href="#" class="nav-link" @click="toggleDropdown($event)">
      {{ menuElt.name }} <vue-feather type="chevron-down"></vue-feather>
    </a>

    <ul
      class="dropdown_menu"
      :class="{ 'tw-hidden': !dropdownVisible && isMobileMode() }"
    >
      <li
        class="nav-item"
        v-for="(subElement, ind) in menuElt.subElements"
        v-bind:key="ind"
      >
        <router-link
          :to="subElement.link"
          class="nav-link"
          @click="$emit('hide-menu', 1)"
          >{{ subElement.name }}</router-link
        >
      </li>
    </ul>
  </li>
</template>
<script>
export default {
  name: "MenuHeaderMultiBtn",
  props: {
    menuElt: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dropdownVisible: false,
    };
  },
  methods: {
    toggleDropdown($event) {
      $event.preventDefault();
      this.dropdownVisible = !this.dropdownVisible;
    },
    isMobileMode() {
      return window.innerWidth < 768;
    },
  },
};
</script>
