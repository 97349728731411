<template>
  <div class="discover-area ptb-80">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6 col-md-12">
          <div class="discover-image">
            <img
              src="../../../assets/img/bigdata-analytics/discover-img1.png"
              alt="image"
            />
            <img
              src="../../../assets/img/bigdata-analytics/discover-img2.jpg"
              alt="image"
            />
          </div>
        </div>

        <div class="col-lg-6 col-md-12">
          <div class="discover-content">
            <h2>Engaging New Audiences through Smart Approach</h2>
            <p>
              There are many variations of passages of Lorem Ipsum available,
              but the majority have suffered alteration in some form, by
              injected humour, or randomised words which don't look even
              slightly believable. If you are going to use a passage.
            </p>
            <a href="#" class="btn btn-primary">Discover More</a>
          </div>
        </div>
      </div>
    </div>

    <div class="analytics-shape1">
      <img
        src="../../../assets/img/bigdata-analytics/analytics-shape1.png"
        alt="image"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "DiscoverContent",
};
</script>